/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { ErrorToast, SuccessToast } from './components';
import { AppLayout } from './components/AppLayout';
import { selectToastState } from './features/ToastSlice/selectors';
import Routes from './routes';
import { ErrorBoundary } from 'app/components/ErrorBoundary';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from '@auth0/auth0-react';
import Config from 'utils/config';
import '../styles/global.css';

const Auth0ProviderWithRedirectCallback: React.FC<
  { children: React.ReactNode } & Auth0ProviderOptions
> = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) =>
    navigate((appState && appState.returnTo) || window.location.pathname);
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

export function App() {
  const { i18n } = useTranslation();
  const toastState = useSelector(selectToastState);
  return (
    <BrowserRouter>
      {/* Wrap the entire application in a global ErrorBoundary as a failsafe. In
      development, you'll see the CRA red screen overlay first, but this ensures
      production won't show a white screen. */}
      <ErrorBoundary>
        <Helmet
          titleTemplate="%s - MyFO Web"
          defaultTitle="MyFO Web"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name="description" content="MyFO Web application" />
        </Helmet>

        <ErrorToast
          isError={toastState.error.show}
          errorText={toastState.error.message}
        />
        <SuccessToast
          isSuccess={toastState.success.show}
          successText={toastState.success.message}
        />
        <Auth0ProviderWithRedirectCallback
          domain={Config.REACT_APP_AUTH0_DOMAIN}
          clientId={Config.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: `${window.location.origin}/continue/auth`,
            audience: Config.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid profile email',
          }}
        >
          <AppLayout>
            <Routes />
          </AppLayout>
        </Auth0ProviderWithRedirectCallback>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
