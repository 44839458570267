import { ReactNode } from 'react';

/* --- STATE --- */
export interface UserData {
  profile: string;
  interests: string;
  fears: string;
  id: string;
  _id?: string;
  firstName: string;
  lastName: string;
  groups: string[];
  email?: string;
  dateOfBirth?: string | null;
  phoneNumber?: string;
  countryCode?: string;
  primary?: string;
  addresses?: UserAddress[];
  primaryTaxJurisdiction?: string;
  maritalStatus?: MaritalStatuses;
  hasAgreements?: boolean | null;
  hasChildren?: boolean | null;
  hasBusiness?: boolean | null;
  demographics?: Demographics;
  verified: boolean;
  account: AccountData;
}

export type AccountData = {
  membership: MembershipStatus;
  memberSince: string;
  expiresAt: string;
  customerId: string;
  paymentMethod: string;
  membershipType: string;
};

export type UserDataInput = Partial<UserData>;

export enum MembershipStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  TRIAL = 'TRIAL',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  TRIAL_END = 'TRIAL_END',
}

export enum MembershipType {
  STANDARD = 'STANDARD',
  ADVISOR = 'ADVISOR',
}

export enum MaritalStatuses {
  SINGLE = 'SINGLE',
  COHABITATING = 'COHABITATING',
  MARRIED = 'MARRIED',
  SEPARATED = 'SEPARATED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
}

export interface UserAddress {
  type: string;
  addressLine: string;
  locality: string;
  region: string;
  postcode: string;
  country: string;
}

export type Demographics = Record<string, Array<string>>;

export type UserFormData = {
  password: string;
  login: string;
  loginMethod: string;
};

export type OauthLoginData = {
  loginMethod: string;
  oauthToken: string;
};

export interface UserStateProps {
  user: UserData | null;
  userEmail: string | null;
  userToken: string | null;
  isFetching: boolean;
  isSuccess: boolean;
  signinError?: Error | null;
  userEmailError?: Error | null;
  signinResponse?: any[];
  formData?: UserFormData;
  getUserError?: Error | null;
  isEditingUser?: boolean;
  editUserError?: Error | null;
  validateTokenError?: Error | null;
  editUserSuccess?: boolean | null;
  validateTokenIsSuccess?: boolean | null;
  verified: boolean;
  remindLaterDate?: number;
}

export interface UserUpdaterProps {
  signIn: Function;
  signOff: Function;
  clearSigninState: Function;
  clearEditState: Function;
  setUser: Function;
  getUser: Function;
  editUser: Function;
  editClient: Function;
  getUserByEmail: Function;
  validateToken: Function;
  useIsTokenValid: () => boolean;
  setRemindLater: Function;
}

export interface UserProviderProps {
  initUser: UserData | null;
  children: ReactNode;
}

export enum SupportedLoginMethods {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  OAUTH = 'OAUTH',
}
