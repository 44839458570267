import { muiTheme } from 'styles/muiTheme';

const buttonBaseStyles = {
  color: 'primary.back',
  borderRadius: '6px',
  padding: '12px',
};

const dividerStyles = {
  borderBottomWidth: '1px',
  borderColor: muiTheme.palette.secondary.light,
  my: '8px',
};

export default {
  buttonBaseStyles,
  parentAccordionTitleStyles: {
    paddingX: 0,
    color: 'text.primary',
    '.MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(90deg)',
      color: 'primary.main',
    },
    '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(-90deg)',
      color: 'primary.main',
    },
  },
  sharedAccordionTitleStyles: {
    paddingX: 0,
    color: 'text.primary',
    '.MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(90deg)',
      color: 'primary.main',
    },
    '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(-90deg)',
      color: 'primary.main',
    },
    ':hover': {
      color: 'text.primary',
    },
  },
  accordionStyles: {
    borderTop: 'none',
  },
  titleAccordionStyles: { marginLeft: 2, fontWeight: 'bold' },
  expandendAccordionStyles: { padding: 0 },
  expandendTitleAccordionStyles: {
    padding: '12px',
    mb: '4px',
    '&.Mui-expanded': {
      color: 'secondary.main',
    },
    '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      color: 'secondary.main',
    },
  },
  expandendEntityAccordionStyles: {
    padding: '0px 12px',
    mb: '4px',
    borderRadius: '6px',
    ':hover': {
      backgroundColor: muiTheme.palette.primary['12p'],
    },
    '&.Mui-expanded': {
      backgroundColor: muiTheme.palette.primary['12p'],
    },
    '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      color: 'secondary.main',
    },
  },
  dividerStyles,
  sidebarStyles: {
    backgroundColor: 'primary.contrast',
    position: 'inherit',
    border: 'none',
    padding: '24px 12px',
  },

  internalSidebarSx: {
    width: '220px',
    height: '100%',
    overflow: 'auto',
  },

  listSx: { width: 'full', p: 0 },

  buttonLigthGrayStyles: {
    ...buttonBaseStyles,
    ':hover': {
      backgroundColor: 'secondary.30p',
      color: 'text.primary',
      '.MuiTypography-root': {
        fontWeight: 'bold !important',
      },
    },
    ':active': {
      backgroundColor: 'primary.12p',
      color: 'primary.dark',
      '.MuiTypography-root': {
        fontWeight: 'bold !important',
      },
    },
  },
  clientButtonStyles: {
    ...buttonBaseStyles,
    ':hover': {
      backgroundColor: 'primary.main',
      color: 'primary.contrast',
      '.MuiTypography-root': {
        fontWeight: 'bold !important',
      },
    },
    ':active': {
      backgroundColor: 'primary.main',
      color: 'primary.contrast',
    },
  },
  addClientButtonStyles: {
    ...muiTheme.variants.buttonLargePrimary,
    width: 'inset',
    justifyContent: 'center',
    color: 'secondary.dark',
    backgroundColor: 'inherit',
    boxShadow: 0,
    borderColor: 'primary.main',
    ':hover': {
      backgroundColor: 'primary.30p',
      color: 'primary.contrast',
      '.MuiTypography-root': {
        fontWeight: 'bold !important',
      },
    },
  },
  addNewButtonStyles: {
    ...muiTheme.variants.buttonLargePrimary,
    mt: '4px',
    width: 'inset',
    justifyContent: 'center',
    ':hover': {
      backgroundColor: 'primary.30p',
      color: 'primary.contrast',
    },
  },
  entitiesDetailsStyles: {
    padding: 0,
  },
  goToEntityButtonStyles: {
    ...muiTheme.variants.outlinedButtonLargePrimary,
    width: 'full',
    mt: '8px',
    py: '5px',
    px: '0px',
    mb: '0.5rem',
    justifyContent: 'center',
    height: 'auto',
    ':hover': {
      backgroundColor: 'primary.30p',
      color: 'primary.contrast',
    },
  },
  containerStyle: {
    padding: '1em',
  },
  noClienttitle: {
    margin: '1em 0',
    color: 'secondary.light',
  },
  noFiltertitle: {
    margin: '1em 0',
    color: 'text.primary',
    fontWeight: 'bold',
  },
  addNewTaskButtonStyles: {
    ...muiTheme.variants.buttonLargePrimary,
    width: 'inset',
    height: '42px',
    textTransform: 'uppercase',
    justifyContent: 'center',
    ':hover': {
      backgroundColor: 'primary.30p',
      color: 'primary.contrast',
    },
  },
  buttonStyle: {
    ...buttonBaseStyles,
    background: 'rgba(156, 150, 142, 0.12)',
    color: 'text.primary',
    mb: '4px',
    ':hover': {
      backgroundColor: 'rgba(156, 150, 142, 0.12)',
      '.MuiTypography-root': {
        fontWeight: 'bold !important',
      },
    },
  },
  outlinedButtonStyles: {
    ...buttonBaseStyles,
    border: '1px solid rgba(16, 24, 32, 0.12)',
  },
  dividerSx: {
    mt: '106px',
    mb: '24px',
  },
  clientListBox: {
    overflowY: 'overlay',
    overflowX: 'hidden',
    width: '100%',
    pr: '3px',
    py: '8px',
    mb: '3px',
    maxHeight: 'calc(100vh - 400px)',
  },
};
