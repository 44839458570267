import { AssetConst, LiabilityConst } from 'constants/keyValue';

export const liquidityClasses = [
  'CASH_AND_CASH_EQUIVALENTS',
  'FOREIGN_CURRENCY',
  'COMODITY',
  'FIXED_INCOME',
  'PUBLIC_EQUITY',
  'CRYPTO',
];

export const cashClasses = ['CASH_AND_CASH_EQUIVALENTS', 'FOREIGN_CURRENCY'];

export const allocationClasses = [
  LiabilityConst.COMMITMENT,
  LiabilityConst.DONATION,
  LiabilityConst.GRANT,
];

export const insuranceWholeLifeClasses = [AssetConst.WHOLE_LIFE];

export const insuranceUniversalClasses = [AssetConst.UNIVERSAL];

export const insuranceTermClasses = [
  AssetConst.TERM_LIFE,
  AssetConst.CRITICAL_ILLNESS,
  AssetConst.DISABILITY,
];

export const ItemsKeysToValues = {
  COMODITY: 'Commodities',
  HEDGE_FUNDS_ALTERNATIVES: 'Hedge Funds & Alternatives',
  CRYPTO: 'Crypto',
  REAL_ESTATE: 'Real Estate',
  PUBLIC_EQUITY: 'Investment & Brokerage Accounts',
  CASH_AND_CASH_EQUIVALENTS: 'Cash Accounts',
  FIXED_INCOME: 'Bonds',
  PRIVATE_EQUITY_FUNDS: 'Private Equity',
  ANGEL_INVENSTING: 'Angel Investments',
  LOAN_RECEIVABLE: 'Loan Receivable',
  FOREIGN_CURRENCY: 'Foreign Currency',
  MISC: 'Miscellaneous',
  OPERATING_COMPANY: 'Operating Company',
  VENTURE_CAPITAL: 'Venture Capital',
  OPTION_RSU: 'Options & RSU',
  CREDIT_CARD: 'Credit Card',
  LINE_OF_CREDIT: 'Line of Credit',
  LOAN_PERSONAL: 'Personal Loan',
  MORTGAGE: 'Mortgage',
  OTHER: 'Other',
  INSURANCE: 'Insurance',
};

export const AssetToValues = {
  GROUP_ASSET: 'Asset',
  GROUP_LIABILITY: 'Liability',
};
