import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DeleteOutlineOutlined,
  GroupAdd,
  LinkOutlined,
  MoreVert,
  VisibilityOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import {
  Asset,
  Item,
  Liability,
  Subsidiary,
  SupportedItemTypes,
} from 'app/features/AssetLiabilitySlice/types';
import { useAssetLiabilitySlice } from 'app/features/AssetLiabilitySlice';
import { selectGroups } from 'app/features/GroupSlice/selectors';
import { TableListCard } from 'app/components/TableListCard';
import { ColumnCustom } from 'app/components/TableListCard/ColumnCustom';
import { ColumnText } from 'app/components/TableListCard/ColumnText';
import { getRoutePath } from 'app/routes/routePath';
import style from './style';
import { formatCurrencyToLocale } from 'app/components/CurrencyValue';
import { useCurrencyState } from 'providers/CurrencyProvider';

interface Props {
  data: Asset | Liability | Subsidiary;
  hideview?: boolean;
  handleLiabilityRemove?: (e?: any, n?: string) => void | undefined;
  onAddStakeholder?: (item: Item) => void;
  onClickEdit: (event: React.MouseEvent<HTMLElement>) => void;
  onClickDelete: (event: React.MouseEvent<HTMLElement>) => void;
  stakeRenderValue?: number;
}

export function AssetRow({
  data,
  hideview,
  handleLiabilityRemove,
  onAddStakeholder,
  stakeRenderValue,
  onClickEdit,
  onClickDelete,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const currencyState = useCurrencyState();
  const groups = useSelector(selectGroups);
  const isOnlineAccount = Boolean(data && data.integrationHash);

  const groupName = React.useMemo(() => {
    return groups.find(({ _id }) => _id === data.groupId)?.groupName || '';
  }, [groups, data]);

  const renderActionButtons = () =>
    hideview ? (
      <Button
        variant="text"
        sx={style.removeButtonStyles}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          handleLiabilityRemove &&
            handleLiabilityRemove(data._id, data.baseContext.name);
        }}
        disableRipple
      >
        <DeleteOutlineOutlined />
      </Button>
    ) : (
      <div style={style.iStyle}>
        {isOnlineAccount && (
          <Tooltip
            title={t('itemShortcuts.tooltips.onlineAccount')}
            placement="top"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            componentsProps={{
              tooltip: {
                sx: style.tooltipSx,
              },
            }}
          >
            <Typography sx={style.buttonSx}>
              <LinkOutlined />
            </Typography>
          </Tooltip>
        )}
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
      </div>
    );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <TableListCard
      id={data._id}
      cardStyles={style.cardListStyles}
      onClick={onClickEdit}
    >
      <ColumnText
        title={data.baseContext?.customName ?? data.baseContext.name}
        subtitle={t('consolidation.labels.name')}
        gridSize={3}
        titleSx={style.titleStyles}
        subtitleSx={style.subtitleStyles}
      />
      <ColumnText
        title={groupName}
        subtitle={t('consolidation.labels.owner')}
        gridSize={3}
        titleSx={style.titleStyles}
        subtitleSx={style.subtitleStyles}
      />
      <ColumnText
        title={formatCurrencyToLocale(
          currencyState.base,
          stakeRenderValue ?? data.baseContext.renderValue,
        )}
        subtitle={t('consolidation.labels.currentValue')}
        gridSize={3}
        titleSx={style.currentValueStyles}
        subtitleSx={style.subtitleStyles}
      />

      <ColumnCustom
        element={renderActionButtons()}
        gridSize={3}
        cellSx={style.buttonsStyles}
      />

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {onAddStakeholder && (
          <MenuItem
            sx={style.menuItemStyles}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation();
              onAddStakeholder(data as Item);
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button sx={style.buttonSx} disableRipple>
                <GroupAdd fontSize="small" />
              </Button>
              <Typography variant="body2">
                {capitalize(t('consolidation.buttons.addStakeholder'))}
              </Typography>
            </Stack>
          </MenuItem>
        )}

        <MenuItem onClick={onClickEdit} sx={style.menuItemStyles}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button sx={style.buttonSx} disableRipple>
              <VisibilityOutlined />
            </Button>
            <Typography variant="body2">
              {capitalize(t('assetEditPage.buttons.viewAsset'))}
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem onClick={onClickDelete} sx={style.menuItemStyles}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button sx={style.buttonSx} disableRipple onClick={onClickDelete}>
              <DeleteOutlineOutlined />
            </Button>
            <Typography variant="body2">
              {capitalize(t('viewAssetPage.buttons.deleteAsset'))}
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </TableListCard>
  );
}
