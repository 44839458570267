import { generatePath } from 'react-router';

const RoutesDictionary = {
  userHome: {
    path: 'user-home',
    raw: 'user-home',
  },
  consolidation: {
    path: 'user-home/consolidation/:groupId',
    raw: 'consolidation',
  },
  advisors: {
    path: 'user-home/advisors',
    raw: 'advisors',
  },
  stakeholder: {
    path: 'user-home/stakeholders/:groupId',
    raw: 'stakeholders',
  },
  addStakeholder: {
    path: 'user-home/stakeholders/:groupId/actions/add-stakeholder',
    raw: 'add-stakeholder',
  },
  stakeholderView: {
    path: 'user-home/stakeholders/:groupId/actions/stakeholder-view/:stakeholderId',
    raw: 'stakeholder-view',
  },
  manageStakeholderAccess: {
    path: 'user-home/stakeholders/:groupId/actions/manage-access/:stakeholderId',
    raw: 'stakeholder-view',
  },
  editStakeholderMeta: {
    path: 'user-home/stakeholders/:groupId/actions/edit-user/:stakeholderId',
    raw: 'edit-user',
  },
  sharedWithMe: {
    path: 'user-home/shared',
    raw: 'shared',
  },
  accounts: {
    path: 'user-home/accounts',
    raw: 'accounts',
  },
  uss: {
    path: 'uss',
    raw: 'uss',
  },
  signin: {
    path: 'uss/signin',
    raw: 'signin',
  },
  register: {
    path: 'uss/register',
    raw: 'register',
  },
  actions: {
    path: 'user-home/consolidation/:groupId/actions',
    raw: 'actions',
  },
  addItem: {
    path: 'user-home/consolidation/:groupId/actions/add-item',
    raw: 'add-item',
  },
  addAsset: {
    path: 'user-home/consolidation/:groupId/actions/add-asset',
    raw: 'add-asset',
  },
  addLiability: {
    path: 'user-home/consolidation/:groupId/actions/add-liability',
    raw: 'add-liability',
  },
  viewLiability: {
    path: 'user-home/consolidation/:groupId/actions/view-liability/:itemId',
    raw: 'view-liability',
  },
  viewAsset: {
    path: 'user-home/consolidation/:groupId/actions/view-asset/:itemId',
    raw: 'view-asset',
  },
  editAsset: {
    path: 'user-home/consolidation/:groupId/actions/edit-asset/:itemId',
    raw: 'edit-asset',
  },
  editLiability: {
    path: 'user-home/consolidation/:groupId/actions/edit-liability/:itemId',
    raw: 'edit-liability',
  },
  settings: {
    path: 'uss/settings',
    raw: 'settings',
  },
  entities: {
    path: 'user-home/entities',
    raw: 'entities',
  },
  guidancePlans: {
    path: 'user-home/consolidation/:groupId/guidance-plans',
    raw: 'guidance-plans',
  },
  verification: {
    path: 'verification',
    raw: 'verification',
  },
  tasks: {
    path: 'user-home/tasks/:groupId',
    raw: 'tasks',
  },
  addTask: {
    path: 'user-home/tasks/:groupId/actions/add-task',
    raw: 'add-task',
  },
  editTask: {
    path: 'user-home/tasks/:groupId/actions/edit-task/:taskId',
    raw: 'edit-task',
  },
  vault: {
    path: 'user-home/vault/:groupId',
    raw: 'vault',
  },
  dashboard: {
    path: 'user-home/dashboard',
    raw: 'dashboard',
  },
  'scenario-modeling': {
    path: 'user-home/scenario-modeling/:groupId',
    raw: 'scenario-modeling',
  },
  docsTermsOfService: {
    path: 'docs/terms-of-service',
    raw: 'terms-of-service',
  },
  faq: {
    path: 'docs/faq',
    raw: 'faq',
  },
};

//TODO Looks sketchy, search a better way to get root path

export type RoutesDictionaryType = keyof typeof RoutesDictionary;
export type RoutePathProps = {
  target: RoutesDictionaryType;
  relative?: boolean;
  raw?: boolean;
  searchParams?: Record<string, string>;
  pathParams?: Record<string, string>;
};

export const getBaseRouteTarget = (path: string): RoutesDictionaryType => {
  const rootPathRaw = path.split('/')[2];
  const rootEntrie = Object.entries(RoutesDictionary).find(
    ([_, value]) => value.raw === rootPathRaw,
  );
  const key = rootEntrie ? rootEntrie[0] : 'consolidation';
  return key as RoutesDictionaryType;
};

export const getRawRouteByPath = (
  path: string,
  pathParams?: Record<string, string>,
  raw?: boolean,
): string | undefined => {
  const [foundEntryKey] =
    Object.entries(RoutesDictionary).find(
      ([_, value]) =>
        generatePath(value[raw ? 'raw' : 'path'], pathParams) === path,
    ) || [];
  return foundEntryKey;
};

export const getRoutePath = ({
  target,
  relative,
  raw,
  searchParams,
  pathParams,
}: RoutePathProps): string => {
  try {
    const urlSearchParams = new URLSearchParams(searchParams);
    const basePath = generatePath(
      RoutesDictionary[target][raw ? 'raw' : 'path'],
      pathParams,
    );
    return `${relative ? '' : '/'}${basePath}${
      searchParams ? `?${urlSearchParams?.toString()}` : ''
    }`;
  } catch (e) {
    throw new Error('Could not resolve to a valid path', {
      cause: { err: e, target, relative, raw, searchParams, pathParams },
    });
  }
};
