import { ValueHistory } from 'app/features/AssetLiabilitySlice/types';
import { TransactionGroupOneTypeList } from 'app/components/Modal/ModalCreateItem/components/AddItemHistoryForm/options';

/**
 * Checks if a transaction type is a "capital distribution."
 */
function isCapitalDistribution(txType: string) {
  return (
    txType === TransactionGroupOneTypeList.CAPITAL ||
    txType === TransactionGroupOneTypeList.RETURN_CAPITAL
  );
}

/**
 * Sums only the capital distribution amounts from the value history,
 * ignoring non-capital distributions (income, dividend, interest, special, etc.).
 */
export function sumCapitalDistributions(history: ValueHistory[] = []): number {
  return history.reduce((acc, record) => {
    if (isCapitalDistribution(record.transactionType ?? '')) {
      const amount = Number(record.distribution ?? 0);
      return acc + amount;
    }
    return acc;
  }, 0);
}
