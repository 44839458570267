import { SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { muiTheme } from 'styles/muiTheme';

export const reactDropZoneStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
  padding: '20px',
  borderColor: muiTheme.palette.primary.main,
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minHeight: 230,
  borderRadius: 23,
} as Record<string, SxProps>;

export const iconContainer = {
  border: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  backgroundColor: 'info.back',
  borderRadius: '50%',
  width: '60px',
  height: '60px',
  marginBottom: 2,
};

export const dropzoneTextContainer = {
  display: 'flex',
};

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const filesContainer = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  px: 2,
  mb: 2,
};

export const fileDescriptionContainer = {
  display: 'flex',
  flex: 1,
};
export const filesIconContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
};

export const fileDescription = {
  flex: 1,
  marginLeft: '16px',
};

export const uploadSx = {
  textDecoration: 'underline',
};
