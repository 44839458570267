import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { muiTheme } from 'styles/muiTheme';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons/Loadable';
import { ClientInformationForm } from 'app/components/Forms/ClientInformationForm';
import style from './style';
import { useAddressBookSlice } from 'app/features/AddressBookSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useUser } from 'providers/UserProvider';
import {
  selectImpersonateToken,
  selectImpersonateTokenSuccess,
} from 'app/features/AddressBookSlice/selectors';

export function AddClient({ onClose, client, viewClient }) {
  const { t } = useTranslation();
  const { editClient, editUserSuccess, editUserError } = useUser();
  const dispatch = useDispatch();
  const { actions } = useAddressBookSlice();
  const impersonateToken = useSelector(selectImpersonateToken);
  const impersonateTokenSuccess = useSelector(selectImpersonateTokenSuccess);
  const isAddNewClient = Boolean(client === undefined);

  const useFormInstance = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstName: client ? client.connectionManagerMeta.firstName : '',
      lastName: client ? client.connectionManagerMeta.lastName : '',
      company: client ? client.connectionManagerMeta.company : '',
      email: client ? client.connectionManagerMeta.email : '',
      phoneNumber: client ? client.connectionManagerMeta.phoneNumber : '',
      countryCode: client ? client.connectionManagerMeta.countryCode : '',
      country: client ? client.connectionManagerMeta.country : '',
      signup: client ? client.connectionManagerMeta.signup : '',
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = useFormInstance;

  const onSubmit = async formData => {
    const userInformation = Object.entries(formData).reduce(
      (userObject: Record<string, string>, [key, value]) => {
        if (value) {
          userObject[key] = value as string;
        }
        return userObject;
      },
      {},
    );
    if (client) {
      if (impersonateTokenSuccess && impersonateToken) {
        const data = {
          _id: client?.connectionManagerMeta._id,
          ...userInformation,
          impersonatingUser: true,
        };
        editClient(data);
      }
    } else {
      dispatch(actions.createImpersonateUser(userInformation));
      onClose();
    }
  };

  useEffect(() => {
    if (editUserSuccess && client) {
      onClose();
    }
  }, [client, editUserSuccess, onClose]);

  return (
    <Box>
      <Box sx={style.titleStyle}>
        {/* Add conditions for Heading on the Modal */}
        <Typography variant="h4">
          {viewClient
            ? capitalize(t('advisors.button.viewClient.details'))
            : isAddNewClient
              ? capitalize(t('advisors.addnew'))
              : capitalize(t('advisors.edit'))}
        </Typography>
      </Box>
      <Typography sx={style.headingStyle} variant="h6">
        {capitalize(t('advisors.clientInfo'))}
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <ClientInformationForm
          hideSignup={Boolean(client)}
          editUserInfo={viewClient}
          useFormInstance={useFormInstance}
        />
        {/* Add conditions for Add Button */}
        {!viewClient && (
          <Box sx={muiTheme.variants.boxBottomButtonsDefault}>
            <BottomRightGroupButtons
              onPrimaryBtnClick={onClose}
              primaryBtnText={capitalize(t('cancelButton'))}
              secondaryBtnText={
                isAddNewClient
                  ? capitalize(t('advisors.addnew'))
                  : capitalize(t('continueButton'))
              }
              secondaryBtnDisabled={!isValid}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
