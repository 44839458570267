import { GroupData, GroupTypes } from 'app/features/GroupSlice/types';
import { TypeTasks } from 'app/features/UserTasksSlice/types';

export const subcategoryList = ['PRIVATE_REIT'];

export const realEstateTypes = [
  'Residential',
  'Commercial',
  'Industrial',
  'Mixed use',
  'Raw land',
  'Development',
];

export const brokerageAccountsTypes = {
  equity: {
    label: 'Stocks',
    color: '#34363B',
  },
  fixed_income: {
    label: 'Bonds',
    color: '#838287',
  },
  cash_equivalent: {
    label: 'Cash',
    color: '#404F49',
  },
  foreign_currency: {
    label: 'Foreign Currency',
    color: '#8BC598',
  },
  other: {
    label: 'Commodities',
    color: '#C1BBB7',
  },
  bonds: {
    label: 'Bonds',
    color: '#838287',
  },
  crypto: {
    label: 'Crypto',
    color: '#51869F',
  },
  commodities: {
    label: 'Commodities',
    color: '#C1BBB7',
  },
};

export const AssetConst = {
  ANGEL_INVENSTING: 'ANGEL_INVENSTING',
  ANGEL: 'ANGEL',
  ART: 'ART',
  CASH_AND_CASH_EQUIVALENTS: 'CASH_AND_CASH_EQUIVALENTS',
  COLLECTIBLES: 'COLLECTIBLES',
  COMODITY: 'COMODITY',
  CONNECTED_ACCOUNTS: 'CONNECTED_ACCOUNTS',
  CRITICAL_ILLNESS: 'CRITICAL_ILLNESS',
  CRYPTO: 'CRYPTO',
  DISABILITY: 'DISABILITY',
  FIXED_INCOME: 'FIXED_INCOME',
  FOREIGN_CURRENCY: 'FOREIGN_CURRENCY',
  HEDGE_FUNDS_ALTERNATIVES: 'HEDGE_FUNDS_ALTERNATIVES',
  HEIRLOOMS: 'HEIRLOOMS',
  INSURANCE: 'INSURANCE',
  LOAN_RECEIVABLE: 'LOAN_RECEIVABLE',
  MISC: 'MISC',
  OPERATING_COMPANY: 'OPERATING_COMPANY',
  OPTION_RSU: 'OPTION_RSU',
  OTHER: 'OTHER',
  OWNERSHIP_STAKE: 'OWNERSHIP_STAKE',
  OWNERSHIP: 'OWNERSHIP',
  PRICE_ROUND: 'PRICE_ROUND',
  PRIVATE_EQUITY_FUNDS: 'PRIVATE_EQUITY_FUNDS',
  PRIVATE_REIT: 'PRIVATE_REIT',
  PUBLIC_EQUITY: 'PUBLIC_EQUITY',
  REAL_ESTATE: 'REAL_ESTATE',
  SAFE: 'SAFE',
  TERM_LIFE: 'TERM_LIFE',
  UNIVERSAL: 'UNIVERSAL',
  VENTURE_CAPITAL: 'VENTURE_CAPITAL',
  WHOLE_LIFE: 'WHOLE_LIFE',
};

export const AssetSubTypesKeysToValues = {
  //TODO  remove unused subtypes
  ANGEL_INVENSTING: 'Angel investing',
  ART: 'Art',
  CASH_AND_CASH_EQUIVALENTS: 'Cash and Cash Equivalents',
  COLLECTIBLES: 'Collectibles',
  CRITICAL_ILLNESS: 'Critical Illness',
  DISABILITY: 'Disability',
  HEDGE_FUNDS_ALTERNATIVES: 'Hedge funds',
  HEIRLOOMS: 'Heirlooms',
  OPTION: 'OPTION',
  OTHER: 'Others',
  OWNERSHIP_STAKE: 'Ownership stake',
  OWNERSHIP: 'Ownership',
  PRICE_ROUND: 'Price Round',
  PRIVATE_EQUITY_FUNDS: 'Private equity funds',
  PRIVATE_REIT: 'Private REIT',
  PUBLIC_EQUITY_FUNDS: 'Public equity funds',
  PUBLIC_EQUITY: 'Public equity funds',
  RSU: 'RSU',
  SAFE: 'SAFE',
  TERM_LIFE: 'Term Life',
  UNIVERSAL: 'Universal',
  VENTURE_CAPITAL: 'Venture capital',
  WHOLE_LIFE: 'Whole Life',
};

export const AssetSubTypesValueToKeys = Object.keys(
  AssetSubTypesKeysToValues,
).reduce(
  (p, v) => ({
    ...p,
    [AssetSubTypesKeysToValues[v]]: v,
  }),
  {} as { [key: string]: string },
);

export const AssetClassesKeysToValues = {
  COMODITY: {
    label: 'Commodities',
    color: '#C1BBB7',
  },
  HEDGE_FUNDS_ALTERNATIVES: {
    label: 'Hedge Funds & Alternatives',
    color: '#B0A9A4',
  },
  CRYPTO: {
    label: 'Crypto',
    color: '#51869F',
  },
  REAL_ESTATE: {
    label: 'Real Estate',
    color: '#A8D2AF',
  },
  PUBLIC_EQUITY: {
    label: 'Investment & Brokerage Accounts',
    subLabel: 'Investment Brokerage (Manual Add)',
    benchmarkLabel: 'Public Equities',
    color: '#34363B',
  },
  CASH_AND_CASH_EQUIVALENTS: {
    label: 'Cash Accounts',
    color: '#404F49',
  },
  FIXED_INCOME: {
    label: 'Bonds',
    color: '#838287',
  },
  PRIVATE_EQUITY_FUNDS: {
    label: 'Private Equity',
    color: '#57575C',
  },
  ANGEL_INVENSTING: {
    label: 'Angel Investments',
    color: '#C4DFC6',
  },
  LOAN_RECEIVABLE: {
    label: 'Loan Receivable',
    color: '#C1BBB7',
  },
  FOREIGN_CURRENCY: {
    label: 'Foreign Currency',
    color: '#8BC598',
  },
  MISC: {
    label: 'Miscellaneous',
    color: '#8D938F',
  },
  OPERATING_COMPANY: {
    label: 'Operating Company',
    color: '#626E68',
  },
  VENTURE_CAPITAL: {
    label: 'Venture Capital',
    color: '#D2CECA',
  },
  OPTION_RSU: {
    label: 'Options & RSU',
    color: '#D2CECA',
  },
  INSURANCE: {
    label: 'Insurance',
    color: '#D2CECA',
  },
  STOCKS: {
    label: 'Stocks',
    color: '#D2CECA',
  },
};

export const LiabilityTypesValuesToKeys = {
  'Credit Card': 'CREDIT_CARD',
  'Line of Credit': 'LINE_OF_CREDIT',
  'Private Loan Payable': 'LOAN_PERSONAL',
  Mortgage: 'MORTGAGE',
  GRANT: 'GRANT',
  COMMITMENT: 'COMMITMENT',
  DONATION: 'DONATION',
  Other: 'OTHER',
};
export const LiabilityTypesKeysToValues = {
  CREDIT_CARD: 'Credit Card',
  LINE_OF_CREDIT: 'Line of Credit',
  LOAN_PERSONAL: 'Private Loan Payable',
  MORTGAGE: 'Mortgage',
  GRANT: 'Grant',
  COMMITMENT: 'Commitment',
  DONATION: 'Donation',
  OTHER: 'Other',
};

export const LiabilityConst = {
  CREDIT_CARD: 'CREDIT_CARD',
  LINE_OF_CREDIT: 'LINE_OF_CREDIT',
  LOAN_PERSONAL: 'LOAN_PERSONAL',
  MORTGAGE: 'MORTGAGE',
  GRANT: 'GRANT',
  COMMITMENT: 'COMMITMENT',
  DONATION: 'DONATION',
  OTHER: 'OTHER',
};

export const LiabilitySubTypesValuesToKeys = {
  Convertible: 'CONVERTIBLE',
  Private: 'PRIVATE',
};

export const LiabilitySubTypesKeysToValues = {
  CONVERTIBLE: 'Convertible',
  PRIVATE: 'Private',
};

export const LiabilitySubTypesConst = {
  CONVERTIBLE: 'CONVERTIBLE',
  PRIVATE: 'PRIVATE',
};

export const ConsolidatedGroupData: GroupData = {
  _id: 'consolidated',
  groupName: 'Consolidated',
  groupNameDisplay: 'Consolidated',
  groupType: GroupTypes.CONSOLIDATED,
  groupAdmin: '',
  groupStakePercentage: '100',
  items: [],
  integrations: [],
  stakeholders: [],
};

export const dataStatusTask = [
  {
    _id: TypeTasks.TO_DO,
    groupName: 'Not Started',
  },
  {
    _id: TypeTasks.DOING,
    groupName: 'To Do',
  },
  {
    _id: TypeTasks.COMPLETED,
    groupName: 'Done',
  },
];

export const typeOptions = [
  {
    label: 'Holding Company',
    value: 'COMPANY',
  },
  {
    label: 'Trust',
    value: 'TRUST',
  },
  {
    label: 'Family Foundations',
    value: 'FAMILY_FOUNDATION',
  },
  {
    label: 'Personal',
    value: 'PERSONAL',
  },
];
