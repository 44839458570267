import { muiTheme } from 'styles/muiTheme';

export default {
  stackContainerStyles: {
    p: '8px 30px',
    lineHeight: 0.1,
    bgcolor: 'primary.main',
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 900,
  },
  imgBoxStyles: { pt: '6px' },
  menuButtonStyles: {
    borderRadius: '70px',
    textTransform: 'none',
    '&.MuiButton-text': {
      borderColor: 'text.primary',
      color: 'text.secondary',
    },
    '&.MuiButton-outlined': {
      borderColor: 'text.primary',
      color: 'text.primary',
      fontWeight: '600',
    },
  },
  profileButtonStyles: {
    fontSize: '12px',
    color: 'primary.contrast',
    textTransform: 'none',
  },
  contactusButtonStyles: {
    textTransform: 'none',
  },
  avatarStyles: { width: 32, height: 32, bgcolor: 'primary.contrast' },
  menuStyles: { zIndex: 999 },
  searchQueryStyles: {
    color: 'primary.contrast',
    width: '300px',
    background: 'rgba(255, 255, 255, 0.15)',
    'input::placeholder': {
      color: 'primary.contrast',
    },
    '& .MuiInputBase-root': {
      '& > fieldset': {
        border: 'none',
      },
    },
  },
  searchIconStyles: {
    color: 'primary.contrast',
    marginRight: '8px',
  },
  logoStyles: { minWidth: 111, minHeight: 32, margin: 0, padding: 0 },
  itemStyles: {
    backgroundColor: 'primary.main',
    color: 'primary.contrast',
    position: 'relative',
    margin: 0,
    padding: 0,
    'svg path': {
      fill: muiTheme.palette.primary.contrast,
    },
    '&:hover': {
      color: 'primary.contrast',
      'svg path': {
        fill: muiTheme.palette.primary.contrast,
      },
    },
  },
  iconStyles: {
    margin: 0,
    padding: 0,
    minWidth: '80px',
  },
};
