import { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { addressBookSaga } from './saga';
import {
  AddressBookState,
  ContactSummary,
  Contact,
  NotificationType,
  ConnectionItemAccessData,
  ContactPayloadOperation,
  UserConnectionPayload,
  ConnectionItemSummary,
  UpdateConnectionPayloadOperation,
  ImpersonateUserConnection,
  ImpersonateIdPayloadOperation,
  ImpersonateDeletePayloadOperation,
  ConnectionStatus,
} from './types';

export const defaultClientInformation: ImpersonateUserConnection = {
  _id: '',
  createdOn: '',
  status: ConnectionStatus.PENDING,
  connectionManager: '',
  connectionManagerMeta: {
    _id: '',
    email: '',
    firstName: undefined,
    lastName: undefined,
    phoneNumber: undefined,
    countryCode: undefined,
    country: undefined,
    address: undefined,
    company: undefined,
    title: undefined,
  },
  targetUserId: '',
};

export const initialState: AddressBookState = {
  isFetching: false,
  isModifying: false,
  isSuccess: false,
  isDeleting: false,
  isAdding: false,
  accessDataLoaded: false,
  contactListLoaded: false,
  contactFilteredListLoaded: false,
  loaded: false,
  error: undefined,
  contactList: [],
  contactSummaryList: [],
  contactImpersonatedSummaryList: [],
  contactSummaryFilteredList: [],
  contactAccessData: undefined,
  contact: undefined,
  connectionItemSummary: [],
  connectionItemSummaryLoaded: false,
  isUpdating: false,
  impersonateToken: '',
  loadImpersonatedClient: false,
  // TODO Why do we need to initialize a whole empty client instead of using undefined?
  impersonatedClient: defaultClientInformation,
};

const slice = createSlice({
  name: 'addressBook',
  initialState,
  reducers: {
    getContactSummaryFilteredList(
      state,
      action: PayloadAction<{ groupId?: string; itemId?: string }>,
    ) {
      state.isFetching = true;
      state.error = undefined;
      state.contactFilteredListLoaded = false;
    },
    getContactSummaryFilteredListSuccess(
      state,
      action: PayloadAction<ContactSummary[]>,
    ) {
      state.isFetching = false;
      state.isSuccess = true;
      state.contactSummaryFilteredList = action.payload;
      state.error = undefined;
      state.contactFilteredListLoaded = true;
    },
    getContactSummaryFilteredListError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.contactFilteredListLoaded = false;
      state.error = action.payload;
    },
    getContactSummaryList(state, action: Action) {
      state.isFetching = true;
      state.error = undefined;
      state.contactListLoaded = false;
    },
    getContactSummaryListSuccess(
      state,
      action: PayloadAction<ContactSummary[]>,
    ) {
      state.isFetching = false;
      state.isSuccess = true;
      state.contactSummaryList = action.payload;
      state.error = undefined;
      state.contactListLoaded = true;
    },
    getContactSummaryListError(state, action: PayloadAction<NotificationType>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.contactListLoaded = false;
      state.error = action.payload;
    },
    loadImpersonatedClient(state, action: Action) {
      state.isFetching = true;
      state.error = undefined;
      state.loaded = false;
    },
    loadImpersonatedClientSuccess(state) {
      state.isFetching = false;
      state.isSuccess = true;
      state.loadImpersonatedClient =
        state.impersonateToken.length > 1 ? true : false;
      state.error = undefined;
      state.loaded = true;
    },
    loadImpersonatedClientError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.loaded = false;
      state.error = action.payload;
    },
    createImpersonateUser(
      state,
      action: PayloadAction<Record<string, string>>,
    ) {
      state.isFetching = true;
      state.error = undefined;
      state.loaded = false;
    },
    createImpersonateUserSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isSuccess = true;
      state.error = undefined;
      state.loaded = true;
    },
    createImpersonateUserError(state, action: PayloadAction<NotificationType>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.loaded = false;
      state.error = action.payload;
    },
    signOutImpersonateUser(state, action: Action) {},
    setImpersonatedUser(
      state,
      action: PayloadAction<ImpersonateUserConnection>,
    ) {
      state.impersonatedClient = action.payload;
    },
    getImpersonateToken(
      state,
      action: PayloadAction<ImpersonateIdPayloadOperation>,
    ) {
      state.isFetching = true;
      state.error = undefined;
      state.loaded = false;
    },
    getImpersonateTokenSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isSuccess = true;
      state.impersonateToken = action.payload.token;
      sessionStorage.setItem('supertoken', action.payload.token);
      sessionStorage.setItem('userImpersonated', 'true');
      state.loadImpersonatedClient = true;
      state.error = undefined;
      state.loaded = true;
    },
    resetImpersonateToken(state) {
      state.isFetching = false;
      state.isSuccess = false;
      sessionStorage.removeItem('supertoken');
      sessionStorage.setItem('userImpersonated', 'false');
      state.loadImpersonatedClient = false;
      state.impersonateToken = '';
      state.error = undefined;
      state.loaded = false;
      state.impersonatedClient = defaultClientInformation;
    },
    getImpersonateTokenError(state, action: PayloadAction<NotificationType>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.loaded = false;
      state.error = action.payload;
    },
    getContactImpersonatedSummaryList(state, action: Action) {
      state.isFetching = true;
      state.error = undefined;
      state.loaded = false;
    },
    getContactImpersonatedSummaryListSuccess(
      state,
      action: PayloadAction<ImpersonateUserConnection[]>,
    ) {
      state.isFetching = false;
      state.isSuccess = true;
      state.contactImpersonatedSummaryList = action.payload;
      state.error = undefined;
      state.loaded = true;
    },
    getContactImpersonatedSummaryListError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.loaded = false;
      state.error = action.payload;
    },
    addConnectionAccount(state, action: PayloadAction<UserConnectionPayload>) {
      state.isFetching = true;
      state.error = undefined;
    },
    addConnectionAccountSuccess(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = true;
      state.error = undefined;
      state.isAdding = true;
    },
    addConnectionAccountError(state, action: PayloadAction<NotificationType>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isAdding = false;
      state.error = action.payload;
    },
    updateContactUserInformation(state, action: PayloadAction<Contact>) {
      state.isFetching = true;
      state.error = undefined;
    },
    updateContactUserInformationSuccess(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = true;
      state.error = undefined;
      state.isModifying = true;
    },
    updateContactUserInformationError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isModifying = false;
      state.error = action.payload;
    },
    getContactSummaryListCleanUp(state, action: Action) {
      state.isFetching = false;
      state.isSuccess = false;
      state.accessDataLoaded = false;
      state.loaded = false;
      state.contactListLoaded = false;
      state.error = undefined;
      state.isModifying = false;
      state.isDeleting = false;
      state.isUpdating = false;
      state.isAdding = false;
      state.contactSummaryList = [];
      state.contactSummaryFilteredList = [];
      state.contactAccessData = undefined;
      state.connectionItemSummary = [];
      state.connectionItemSummaryLoaded = false;
    },
    getContactImpersonatedSummaryListCleanUp(state, action: Action) {
      state.isFetching = false;
      state.isSuccess = false;
      state.accessDataLoaded = false;
      state.loaded = false;
      state.contactListLoaded = false;
      state.error = undefined;
      state.isModifying = false;
      state.isDeleting = false;
      state.isUpdating = false;
      state.isAdding = false;
      state.contactImpersonatedSummaryList = [];
      state.loadImpersonatedClient = false;
      state.contactAccessData = undefined;
      state.connectionItemSummary = [];
      state.connectionItemSummaryLoaded = false;
      state.impersonatedClient = defaultClientInformation;
    },
    getContactAccessDataCleanUp(state, action: Action) {
      state.isFetching = false;
      state.accessDataLoaded = false;
      state.error = undefined;
      state.isModifying = false;
      state.contactAccessData = undefined;
    },
    deleteAccountConnection(
      state,
      action: PayloadAction<ContactPayloadOperation>,
    ) {
      state.isFetching = true;
      state.error = undefined;
    },
    deleteAccountConnectionSuccess(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isSuccess = true;
      state.isDeleting = true;
      state.isFetching = false;
      state.error = undefined;
    },
    deleteAccountConnectionError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isDeleting = false;
      state.error = action.payload;
    },
    deleteImpersonateConnection(
      state,
      action: PayloadAction<ImpersonateDeletePayloadOperation>,
    ) {
      state.isFetching = true;
      state.error = undefined;
    },
    deleteImpersonateConnectionSuccess(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isSuccess = true;
      state.isDeleting = true;
      state.isFetching = false;
      state.error = undefined;
    },
    deleteImpersonateConnectionError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isDeleting = false;
      state.error = action.payload;
    },
    getContactAccessData(
      state,
      action: PayloadAction<ContactPayloadOperation>,
    ) {
      state.isFetching = true;
      state.error = undefined;
      state.accessDataLoaded = false;
    },
    getContactAccessDataSuccess(
      state,
      action: PayloadAction<ConnectionItemAccessData>,
    ) {
      state.isFetching = false;
      state.isSuccess = true;
      state.contactAccessData = action.payload;
      state.error = undefined;
      state.accessDataLoaded = true;
    },
    getContactAccessDataError(state, action: PayloadAction<NotificationType>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.accessDataLoaded = false;
      state.contactAccessData = undefined;
      state.error = action.payload;
    },
    getConnectionItemSummary(state, action: Action) {
      state.isFetching = true;
      state.error = undefined;
      state.connectionItemSummaryLoaded = false;
    },
    getConnectionItemSummarySuccess(
      state,
      action: PayloadAction<ConnectionItemSummary[]>,
    ) {
      state.isFetching = false;
      state.isSuccess = true;
      state.connectionItemSummary = action.payload;
      state.error = undefined;
      state.connectionItemSummaryLoaded = true;
    },
    getConnectionItemSummaryError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.connectionItemSummaryLoaded = false;
      state.connectionItemSummary = [];
      state.error = action.payload;
    },
    updateContactAccessData(
      state,
      action: PayloadAction<ConnectionItemAccessData & ContactPayloadOperation>,
    ) {
      state.isFetching = true;
      state.error = undefined;
    },
    updateContactAccessDataSuccess(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = true;
      state.error = undefined;
      state.isModifying = true;
    },
    updateContactAccessDataError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isModifying = false;
      state.error = action.payload;
    },
    updateStatusAccountConnection(
      state,
      action: PayloadAction<UpdateConnectionPayloadOperation>,
    ) {
      state.isFetching = true;
      state.error = undefined;
    },
    updateStatusAccountConnectionSuccess(
      state,
      action: PayloadAction<ConnectionItemSummary[]>,
    ) {
      state.isSuccess = true;
      state.isUpdating = true;
      state.isFetching = false;
      state.connectionItemSummary = action.payload;
      state.error = undefined;
    },
    updateStatusAccountConnectionError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isUpdating = false;
      state.error = action.payload;
    },
  },
});

export const { actions: userTasksActions, reducer } = slice;

export const useAddressBookSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: addressBookSaga });
  return { actions: slice.actions };
};
