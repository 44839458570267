import moment from 'moment';
import {
  Item,
  SupportedItemTypes,
  SupportedStatus,
  LiabilityItemTypes,
  ValueHistory,
} from '../app/features/AssetLiabilitySlice/types';
import { GroupCalculations, GroupData } from '../app/features/GroupSlice/types';
import { currencyConversion } from '../app/components/CurrencyValue';
import { CurrencyStateProps } from 'providers/CurrencyProvider/types';
import {
  allocationClasses,
  cashClasses,
  liquidityClasses,
} from 'constants/asset-liability';
import {
  calcPercent,
  getPositionsValue,
  getPositionsValueWithCash,
} from './assetValues';
import { ConsolidatedGroupData } from 'constants/keyValue';
import { emptyGroupCalculations } from 'app/features/GroupSlice';

export type ItemWithBaseContext = Item & {
  baseContext: { value: number; assetClass: string };
};

type ExtractCalcFromItemsProps = {
  currencyState: CurrencyStateProps;
  filteredGroups: GroupData[];
};

// This function determines how an item value affects total net worth: (0) to ignore, (1) to add and (-1) to subtract
const getItemValueFactor = (item: Item): number => {
  // Assets will add up to total net worth
  if (item.itemType === SupportedItemTypes.GROUP_ASSET) return 1;

  // Allocations should be ignored when calculating net worth
  // It shouldn't be treated as a liability in the calculations
  if (allocationClasses.includes(item.baseContext.type)) return 0;

  // other than that (liability, or unknown types) will subtract from it
  return -1;
};

export const getGroupItemValue =
  (groupPercentage: string | number, isConsolidated: boolean) =>
  (value: string | number) =>
    isConsolidated
      ? calcPercent(Number(value) || 0, Number(groupPercentage))
      : Number(value) || 0;

const getReturnRecordIndex = (
  recordHistory: Record<string, unknown>[],
): { quaterlyRecord: number; annualRecord: number } => {
  const historyLenght = recordHistory.length;
  if (!historyLenght) return { quaterlyRecord: -1, annualRecord: -1 };
  let quaterlyRecord: number | undefined;
  let annualRecord: number | undefined;
  const annualDate = moment().startOf('day').subtract(1, 'year');
  const quaterlyDate = moment().startOf('day').subtract(90, 'days');
  const lastRecordDate = moment(
    recordHistory[historyLenght - 1].date as string,
    'MM/DD/YYYY',
  ).startOf('day');
  if (annualRecord === undefined && lastRecordDate.isBefore(annualDate)) {
    annualRecord = historyLenght - 1;
  }
  if (quaterlyRecord === undefined && lastRecordDate.isBefore(quaterlyDate)) {
    quaterlyRecord = historyLenght - 1;
  }
  recordHistory.some((record, index) => {
    const recordDate = moment(record.date as string, 'MM/DD/YYYY').startOf(
      'day',
    );
    if (annualRecord === undefined && recordDate.isAfter(annualDate)) {
      annualRecord = index - 1;
    }
    if (quaterlyRecord === undefined && recordDate.isAfter(quaterlyDate)) {
      quaterlyRecord = index - 1;
    }
    if (annualRecord !== undefined && quaterlyRecord !== undefined) {
      return true;
    }
    return false;
  });
  if (annualRecord === undefined) {
    annualRecord = -1;
  }
  if (quaterlyRecord === undefined) {
    quaterlyRecord = -1;
  }
  return { quaterlyRecord, annualRecord };
};

export const getConsolidationItemCalculations = ({
  currencyState,
  filteredGroups,
}: ExtractCalcFromItemsProps): Record<string, GroupCalculations> => {
  return filteredGroups
    .map((group: GroupData): GroupCalculations => {
      const groupPercentage = Number(group.groupStakePercentage);
      const _id = group._id;
      return group?.items
        .filter(i => i.status === SupportedStatus.ACTIVE)
        .reduce((calculations: GroupCalculations, item: Item) => {
          const transformLocaleCurrency = (value: unknown) =>
            currencyConversion(
              currencyState,
              item.baseContext.currency,
              Number(value) || 0,
            );
          const itemContext = item.baseContext;
          const itemValueHistory: ValueHistory[] =
            itemContext.valueHistory || [];
          const { quaterlyRecord, annualRecord } =
            getReturnRecordIndex(itemValueHistory);
          const itemValueFactor = getItemValueFactor(item);
          let itemValue = Number(itemContext.value);
          let availableCash = cashClasses.includes(itemContext.type)
            ? itemValue
            : 0;
          let availableLiquidity = liquidityClasses.includes(itemContext.type)
            ? itemValue
            : 0;

          if (itemContext?.positions?.length) {
            const totalValue = getPositionsValue(itemContext?.positions);
            itemValue = totalValue;
            availableLiquidity = totalValue;
            availableCash += getPositionsValueWithCash(itemContext?.positions);
          }

          const commitmentValue =
            itemContext?.type === LiabilityItemTypes.COMMITMENT ? itemValue : 0;
          const donationValue =
            itemContext?.type === LiabilityItemTypes.DONATION ? itemValue : 0;
          const grantValue =
            itemContext?.type === LiabilityItemTypes.GRANT ? itemValue : 0;

          const qItemValue =
            Number(itemValueHistory?.at(quaterlyRecord)?.value) ?? itemValue;
          const aItemValue =
            Number(itemValueHistory?.at(annualRecord)?.value) ?? itemValue;
          const leverageValue = itemContext.isLeverageable
            ? calcPercent(
                itemValue,
                Number(itemContext.leveragePercentage),
                false,
              )
            : 0;
          const totalCommitment = itemContext.totalCommitment || 0;
          const remainingCommitment = itemContext.remainingCommitment || 0;
          const totalNetWorth =
            calculations.totalNetWorth +
            transformLocaleCurrency(itemValue * itemValueFactor);
          const annualNetWorth =
            calculations.annualNetWorth +
            transformLocaleCurrency(aItemValue * itemValueFactor);
          const quarterlyNetWorth =
            calculations.quarterlyNetWorth +
            transformLocaleCurrency(qItemValue * itemValueFactor);

          const commitmentTotal =
            calculations.commitmentTotal +
            transformLocaleCurrency(commitmentValue);
          const grantTotal =
            calculations.grantTotal + transformLocaleCurrency(grantValue);
          const donationTotal =
            calculations.donationTotal + transformLocaleCurrency(donationValue);

          return {
            totalNetWorth,
            availableLiquidity:
              calculations.availableLiquidity +
              transformLocaleCurrency(availableLiquidity),
            availableLeverage:
              calculations.availableLeverage +
              transformLocaleCurrency(leverageValue),
            totalCommitment:
              calculations.totalCommitment +
              transformLocaleCurrency(totalCommitment),
            remainingCommitment:
              calculations.remainingCommitment +
              transformLocaleCurrency(remainingCommitment),
            annualReturn: (totalNetWorth - annualNetWorth) / annualNetWorth,
            quarterlyReturn:
              (totalNetWorth - quarterlyNetWorth) / quarterlyNetWorth,
            annualNetWorth,
            quarterlyNetWorth,
            availableCash:
              calculations.availableCash +
              transformLocaleCurrency(availableCash),
            groupPercentage,
            commitmentTotal,
            grantTotal,
            donationTotal,
            _id,
          };
        }, emptyGroupCalculations);
    })
    .reduce((groupCalculationDictionary, groupCalculations) => {
      groupCalculationDictionary[groupCalculations._id] = groupCalculations;
      const consolidatedCalculations =
        groupCalculationDictionary[ConsolidatedGroupData._id] ||
        emptyGroupCalculations;
      const convertValuePercentage = (value: number) =>
        calcPercent(
          Number(value) || 0,
          Number(groupCalculations.groupPercentage),
        );
      const totalNetWorth =
        consolidatedCalculations.totalNetWorth +
        convertValuePercentage(groupCalculations.totalNetWorth);
      const quarterlyNetWorth =
        consolidatedCalculations.quarterlyNetWorth +
        convertValuePercentage(groupCalculations.quarterlyNetWorth);
      const annualNetWorth =
        consolidatedCalculations.annualNetWorth +
        convertValuePercentage(groupCalculations.annualNetWorth);
      groupCalculationDictionary[ConsolidatedGroupData._id] = {
        totalNetWorth,
        availableLiquidity:
          consolidatedCalculations.availableLiquidity +
          convertValuePercentage(groupCalculations.availableLiquidity),
        availableLeverage:
          consolidatedCalculations.availableLeverage +
          convertValuePercentage(groupCalculations.availableLeverage),
        totalCommitment:
          consolidatedCalculations.totalCommitment +
          convertValuePercentage(groupCalculations.totalCommitment),
        remainingCommitment:
          consolidatedCalculations.remainingCommitment +
          convertValuePercentage(groupCalculations.remainingCommitment),
        annualReturn: (totalNetWorth - annualNetWorth) / annualNetWorth,
        quarterlyReturn:
          (totalNetWorth - quarterlyNetWorth) / quarterlyNetWorth,
        annualNetWorth,
        quarterlyNetWorth,
        availableCash:
          consolidatedCalculations.availableCash +
          convertValuePercentage(groupCalculations.availableCash),
        groupPercentage: 100,
        commitmentTotal:
          consolidatedCalculations.commitmentTotal +
          convertValuePercentage(groupCalculations.commitmentTotal),
        grantTotal:
          consolidatedCalculations.grantTotal +
          convertValuePercentage(groupCalculations.grantTotal),
        donationTotal:
          consolidatedCalculations.donationTotal +
          convertValuePercentage(groupCalculations.donationTotal),
        _id: ConsolidatedGroupData._id,
      };
      return groupCalculationDictionary;
    }, {});
};
