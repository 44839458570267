import { SharesValues } from 'constants/stakeholder';
import { SupportedItemTypes } from '../AssetLiabilitySlice/types';

export type Contact = {
  targetUserId: string;
  connectionManager: string;
  targetUserMeta: UserBasicInfo;
  connectionManagerMeta?: UserBasicInfo;
  canImpersonate?: boolean;
};

export type ContactSummary = {
  groupIdList: string[];
  accessList: string[];
  ownershipList: number[];
  tags: string[];
  itemId?: string;
} & Contact;

export type UserConnectionPayload = {
  groupId: string;
  connectionManager: string;
  targetUserMeta: UserBasicInfo;
  accessLevel: ConnectionAccessLevels;
  tags: string[];
  groupStakePercentage?: number;
  itemAccess?: ItemAccessDeclaration;
  canImpersonate?: boolean;
  notify?: boolean; // Enable notification system to add new stakeholder flow | default true
  vote?: boolean; // informative meta about sharing, default empty | only available when ownership 'yes'
  ownership?: 'yes' | 'no'; // boolean-like variable, default 'no'
  ownershipType?: 'BENEFICIARY' | 'SETTLOR' | 'SUBSIDARY'; // only available when ownership 'yes'
  sharesOwned?: number; // only available when ownership 'yes'
  share?: keyof typeof SharesValues;
};

export type UserConnection = {
  _id: string;
  targetUserId: string;
  acknowledgedByTarget?: boolean;
  canImpersonate?: boolean;
  itemAccess: ItemAccessDeclaration[];
  createdOn: Date;
  completedOn: Date;
} & UserConnectionPayload;

export type AddressBookState = {
  isFetching: boolean;
  isAdding: boolean;
  isModifying: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isSuccess: boolean;
  accessDataLoaded: boolean;
  connectionItemSummaryLoaded: boolean;
  loaded: boolean;
  contactListLoaded: boolean;
  contactFilteredListLoaded: boolean;
  error?: NotificationType;
  contactSummaryList: ContactSummary[];
  //TODO probably not needed
  contactImpersonatedSummaryList: ImpersonateUserConnection[];
  contactSummaryFilteredList: ContactSummary[];
  contactAccessData?: ConnectionItemAccessData;
  connectionItemSummary: ConnectionItemSummary[];
  contactList: Contact[];
  contact?: Contact;
  impersonateToken: string;
  loadImpersonatedClient?: Boolean;
  impersonatedClient: ImpersonateUserConnection;
};

export type UserBasicInfo = {
  _id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  countryCode?: string;
  country?: string;
  address?: string;
  company?: string;
  title?: string;
};

export type ItemAccessDeclaration = {
  itemType: string;
  accessLevel: ConnectionAccessLevels;
  tags: string[];
  itemId: string;
};

export type ConnectionItemAccessData = {
  targetUserId: string;
  groupId?: string;
  entityAccessList: ConnectionItemAccessDataList[];
  itemAccessList: ConnectionItemAccessDataList[];
};

export type ConnectionItemAccessDataList = {
  _id: string;
  groupId?: string;
  tags: string[];
  accessLevel: ConnectionAccessLevels;
  name: string;
};

export type ConnectionItemSummary = {
  type: 'Entity' | 'Item' | 'Impersonate';
  groupStakePercentage?: number;
  createdOn: string;
  status: ConnectionStatus;
  connectionManagerMeta: UserBasicInfo;
  connectionManager: string;
  targetUserId: string;
  targetUserMeta: UserBasicInfo;
  itemId?: string;
  itemType?: SupportedItemTypes;
} & ConnectionItemAccessDataList;

export type ContactPayloadOperation = {
  targetUserId: string;
  connectionManager: string;
  groupId?: string;
  canImpersonate?: boolean;
};

export type ImpersonateDeletePayloadOperation = {
  clientId: string;
};

export type ImpersonateIdPayloadOperation = {
  userId: string;
};

export type UpdateConnectionPayloadOperation = {
  userConnectionId: string;
  status:
    | ConnectionStatus.CONTESTED
    | ConnectionStatus.ACCEPTED
    | ConnectionStatus.DENIED;
  reason?: string;
};

export enum ConnectionAccessLevels {
  READ_ONLY = 'READ_ONLY',
  NO_ACCESS = 'NO_ACCESS',
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
}

export enum ConnectionStatus {
  PENDING = 'PENDING',
  CONTESTED = 'CONTESTED',
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
}

export interface NotificationType {
  message: string;
}

export type ImpersonateUserConnection = {
  _id: string;
  createdOn: string;
  status: ConnectionStatus;
  connectionManager: string;
  connectionManagerMeta: UserBasicInfo;
  targetUserId: string;
  type?: SupportedItemTypes;
};
