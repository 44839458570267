import { muiTheme } from 'styles/muiTheme';
export default {
  cardContainerStyles: {
    padding: '12px',
    boxShadow: muiTheme.shadows[12],
    borderColor: 'primary.main',
  },
  headerContainerStyles: {
    margin: '0 1em',
    padding: '1em',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textTransform: 'capitalize',
  },
  defaultButtonStyles: {
    textTransform: 'uppercase',
    margin: '24px',
    color: 'primary.contrast',
    padding: '8px 22px',
  },
  noClientStyles: {
    display: 'grid',
    gap: 1,
    alignItems: 'center',
    justifyItems: 'center',
    padding: '28px 30px',
    margin: '0 12px',
    backgroundColor: 'secondary.12p',
    border: `1px solid`,
    borderColor: 'secondary.8p',
    borderRadius: '4px',
    width: 'initial',
  },
  containerSx: {
    padding: '1em 0',
    margin: '1em 0',
  },
  divider: {
    margin: '32px 12px',
    borderColor: 'secondary.30p',
  },
  bottomText: {
    margin: '32px 12px',
    padding: '0 8em',
    whiteSpace: 'pre-line',
    textAlign: 'center',
    color: 'secondary.main',
  },
  modelStyles: {
    width: '800px',
  },
};
