/* eslint-disable */
import React, {
  useMemo,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Box, Typography, IconButton, LinearProgress } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Close } from '@mui/icons-material';

import { BaseContext } from 'app/features/AssetLiabilitySlice/types';
import {
  reactDropZoneStyle,
  dropzoneTextContainer,
  fileDescriptionContainer,
  iconContainer,
  VisuallyHiddenInput,
  filesContainer,
  filesIconContainer,
  fileDescription,
  uploadSx,
} from './style';
import { bytesToSize } from './utils';
import { statusColors } from './constants';
import { UploadFileType } from 'app/pages/vault/utils/fileUpload.utils';

export interface PropsForm {
  formInstance: UseFormReturn<BaseContext>;
  name: string;
  onRead?: (data: any) => void;
  onReadCsv?: (data) => void;
  fileType?: string;
  disclaimer?: string;
  accept?: any;
  setDataPerFile?: Dispatch<SetStateAction<UploadFileType[]>>;
}

export const UploadFile: React.FC<PropsForm> = ({
  name,
  formInstance: { register, unregister },
  onRead,
  fileType = 'CSV',
  accept = '',
  setDataPerFile,
  onReadCsv,
}) => {
  const { t } = useTranslation();

  const progress = 100;
  const [status, setStatus] = useState('LOADING');
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    setStatus('COMPLETE');
    setFiles(prev => [...prev, ...acceptedFiles]);
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onload = e => {
        const csvString = e.target?.result;
        const data = {
          name: file.name,
          size: file.size,
          type: file.type,
          result: reader.result,
          file,
        };

        if (setDataPerFile) {
          setDataPerFile(prev => [...prev, data]);
        }

        onRead?.(data);
        onReadCsv?.(csvString);
      };
      reader.readAsText(file);
    });
  };

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
      accept,
      multiple: true,
    });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  const onClose = (item: string) => {
    const fileToRemove = files.filter(file => file.name !== item);
    setFiles(fileToRemove);
  };

  const style = useMemo(
    () => ({
      ...reactDropZoneStyle,
      ...(isDragActive ? { backgroundColor: '#e8f5e9' } : {}),
    }),
    [isDragActive],
  );

  return (
    <>
      <Box {...getRootProps({ style })}>
        <VisuallyHiddenInput type="file" {...getInputProps()} />
        <Box sx={iconContainer}>
          <UploadFileIcon color="primary" fontSize="large" />
        </Box>

        <Box sx={dropzoneTextContainer}>
          <Typography variant="body1" color="primary.main" sx={uploadSx}>
            {capitalize(t('uploadFile.click'))}
          </Typography>
          <Typography variant="body1" ml={1} color="text.primary">
            {capitalize(t('uploadFile.description'))}
          </Typography>
        </Box>
        <Typography variant="body2" color="secondary.main">
          {capitalize(t('uploadFile.fileAccepted', { fileType }))}
        </Typography>
        <>
          {fileRejections.length > 0 && (
            <Typography
              variant="bodyAccent"
              color="error.main"
              fontWeight="bold"
              mt={2}
            >
              {t('uploadFile.fileNotSupported')}
            </Typography>
          )}
        </>
      </Box>
      {files.map(file => (
        <Box key={file.name} sx={filesContainer}>
          <>
            <Box sx={fileDescriptionContainer}>
              <Box
                sx={{
                  ...filesIconContainer,
                  backgroundColor: statusColors[status].uploadBox,
                }}
              >
                <UploadFileIcon
                  color={statusColors[status].icon}
                  fontSize="medium"
                />
              </Box>
              <Box sx={fileDescription}>
                <Typography variant="body2">{file.name}</Typography>
                <Typography
                  variant="body2"
                  color={statusColors[status].text}
                  mb={2}
                >
                  {bytesToSize(file.size)} • {status}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  color={statusColors[status].progress}
                  value={progress}
                />
              </Box>
            </Box>

            <IconButton onClick={() => onClose(file.name)}>
              <Close color={statusColors[status].icon} />
            </IconButton>
          </>
        </Box>
      ))}
    </>
  );
};
