import { formatCurrencyToLocale } from 'app/components/CurrencyValue';
import {
  Asset,
  AssetBaseContext,
  AssetByType,
  BaseContext,
  HoldingReadingInterface,
  Item,
  SupportedItemTypes,
  Liability,
  LiabilityByType,
  Position,
  PositionWithUpdate,
  ValuationChoices,
  ValueHistory,
  SupportedStatus,
  Subsidiary,
  SubsidiaryByType,
} from 'app/features/AssetLiabilitySlice/types';
import { GroupData } from 'app/features/GroupSlice/types';
import { ValidCurrencies } from 'providers/CurrencyProvider/types';
import {
  AssetConst,
  AssetSubTypesKeysToValues,
  AssetClassesKeysToValues,
  LiabilityTypesKeysToValues,
} from '../constants/keyValue';
import moment from 'moment';
import {
  TransactionGroupThreeTypeList,
  TransactionGroupOneTypeList,
} from 'app/components/Modal/ModalCreateItem/components/AddItemHistoryForm/options';
import { SupportedPESubtypesList } from 'app/components/Modal/ModalCreateItem/components/SelectInvestmentType/model';
import { SupportedRESubtypesList } from 'app/components/Modal/ModalCreateItem/components/SelectRealEstate/model';
import { sumCapitalDistributions } from 'utils/capitalDistributions';

export interface ItemTypeList {
  itemTypeList: Set<string>;
  itemListByType: Record<string, Asset[] | Liability[] | Subsidiary[]>;
}

export const calcPercent = (
  total: number,
  percentage?: number,
  defaultPercent = true,
): number =>
  !defaultPercent && !percentage ? 0 : (total * (percentage || 100)) / 100;

function getAngelValue(baseContext: AssetBaseContext) {
  const valueSafe =
    Number(baseContext.recentValuation) ?? Number(baseContext.purchasePrice);
  const valueAngel =
    Number(baseContext.sharesNumber) *
    Number(baseContext.recentSharePrice || baseContext.sharePrice);
  const value =
    baseContext.subType === AssetConst.SAFE ? valueSafe : valueAngel;

  if (value) {
    return Number(value);
  }
  return 0;
}

/**
 * For assets like PE/VC/RE funds, this function calculates their "investment value."
 *
 * - If transactionType === UPDATE, we simply return the recentValuation.
 * - Otherwise, we pick either recentValuation or totalCommitment as our base.
 * - Then we subtract ONLY the sum of capital distributions from the value history,
 *   ignoring non-capital distributions (income, dividend, interest, special).
 */
export function getInvestmentValue(baseContext: AssetBaseContext): number {
  // 1) Decide our base "currentTotalValue."
  if (baseContext.transactionType === TransactionGroupOneTypeList.UPDATE) {
    // If user specifically used an "UPDATE" event, return that recentValuation
    return Number(baseContext.recentValuation) || 0;
  }

  let currentTotalValue = 0;
  if (!baseContext.hasRecentValuation) {
    // If we have no "recentValuation," fallback to totalCommitment
    currentTotalValue = Number(baseContext.totalCommitment);
  } else {
    currentTotalValue = Number(baseContext.recentValuation);
  }

  // 2) Sums only capital distributions from the entire valueHistory
  const totalCapitalDist = sumCapitalDistributions(
    baseContext.valueHistory || [],
  );

  // 3) Subtract only the capital distributions. No effect from non-capital lines.
  const finalValue = currentTotalValue - totalCapitalDist;

  // 4) Return result, clamped at zero in case of overshoot
  return Math.max(finalValue, 0);
}

function getOperatingCompanyValue(baseContext: AssetBaseContext) {
  const recentValuation = Number(baseContext.recentValuation);
  const shareCalculatedValue =
    Number(baseContext.sharesNumber) *
    Number(baseContext.recentSharePrice || baseContext.sharePrice);
  return Number.isNaN(recentValuation) ||
    baseContext.recentValuation === undefined ||
    baseContext.recentValuation === null
    ? shareCalculatedValue ?? 0
    : recentValuation;
}

function getMiscValue(baseContext: AssetBaseContext) {
  const value = baseContext.recentValuation ?? baseContext.purchasePrice;
  return Number(value) ?? 0;
}

function getInsuranceValue(baseContext: AssetBaseContext) {
  if (baseContext.subType === AssetConst.TERM_LIFE) return 0;
  const value = baseContext.recentValuation ?? baseContext.cashSurrenderValue;
  return Number(value) ?? 0;
}

function getLoanValue(baseContext: AssetBaseContext): number {
  const value =
    Number(baseContext.principalAmount) + Number(baseContext.interestAccured);
  return Number(value) ?? 0;
}

function getAngelCost(
  baseContext: AssetBaseContext,
  newBaseContext: AssetBaseContext,
): number {
  const newShares = Number(newBaseContext.sharesPurchased);
  if (baseContext.subType === AssetConst.SAFE) {
    return Number(baseContext.purchasePrice);
  }
  if (!newBaseContext.name) {
    return (
      Number(baseContext.sharesPurchased) * Number(baseContext.recentSharePrice)
    );
  }
  if (newBaseContext.transactionType !== TransactionGroupThreeTypeList.BUY) {
    return Number(baseContext.sharesNumber)
      ? (Number(baseContext.cost) / Number(baseContext.sharesNumber)) *
          Number(newBaseContext.sharesNumber)
      : 0;
  }
  const newCost = newShares * Number(newBaseContext.recentSharePrice);
  return baseContext.cost + newCost;
}
// TODO: remove when item calculations are well tested & approved
// function getRealEstateCost(
//   baseContext: AssetBaseContext,
//   newBaseContext: AssetBaseContext,
//   newValue?: number,
// ): number {
//   const value = newValue || getMiscValue({ ...baseContext, ...newBaseContext });
//   const increasedPercentage =
//     newBaseContext.percentageOwned - baseContext.percentageOwned;
//   const increasedCost =
//     increasedPercentage > 0 ? calcPercent(value, increasedPercentage) : 0;
//   const initialCost =
//     Number(baseContext.cost) ||
//     calcPercent(Number(baseContext.purchasePrice), baseContext.percentageOwned);
//   const cost =
//     increasedPercentage > 0 ? initialCost + increasedCost : initialCost;
//   return cost;
// }

function getInvestmentCost(
  baseContext: AssetBaseContext,
  newBaseContext: AssetBaseContext,
): number {
  let cost = 0;
  if (newBaseContext.name) {
    cost =
      Number(baseContext.cost || 0) + Number(newBaseContext.partialCommitment);
  } else {
    cost = Number(baseContext.cost) || Number(baseContext.partialCommitment);
  }

  if (
    newBaseContext.name &&
    newBaseContext.transactionType === TransactionGroupOneTypeList.CAPITAL
  ) {
    return cost - Number(newBaseContext.distribution || 0);
  }

  if (
    newBaseContext.name &&
    newBaseContext.transactionType ===
      TransactionGroupOneTypeList.COST_ADJUSTMENT
  ) {
    return cost + Number(newBaseContext.costAdjustment || 0);
  }

  return cost;
}

function getOperatingCompanyCost(
  baseContext: AssetBaseContext,
  newBaseContext: AssetBaseContext,
): number {
  const newShares = Number(newBaseContext.sharesPurchased ?? 0);
  if (!newBaseContext.name) {
    return (
      Number(baseContext.sharesPurchased ?? baseContext.sharesNumber) *
      Number(baseContext.recentSharePrice ?? baseContext.sharePrice)
    );
  }
  if (newBaseContext.transactionType !== TransactionGroupThreeTypeList.BUY) {
    return Number(baseContext.sharesNumber)
      ? (Number(baseContext.cost) / Number(baseContext.sharesNumber)) *
          Number(newBaseContext.sharesNumber)
      : 0;
  }
  const newCost = newShares * Number(newBaseContext.recentSharePrice);
  return baseContext.cost + newCost;
}

function getMiscCost(baseContext: AssetBaseContext): number {
  return Number(baseContext.purchasePrice);
}

function getInsuranceCost(baseContext: AssetBaseContext): number {
  if (baseContext.subType === AssetConst.TERM_LIFE) return 0;
  return Number(baseContext.premiumOutlay);
}

function getLoanCost(baseContext: AssetBaseContext): number {
  return Number(baseContext.principalAmount);
}

//TODO refactor calculations on RSU / remove duplicated code

function getBuyOptionValue(baseContext: AssetBaseContext) {
  const vestingData = baseContext.vestingData;
  const vestingShares = getVestedSharesTotal(vestingData);
  const marketPrice = baseContext.marketPrice;
  const strikePrice = baseContext.strikePrice;
  return vestingShares * (marketPrice - strikePrice);
}
function getSellOptionValue(baseContext: AssetBaseContext) {
  const vestingData = baseContext.vestingData;
  const vestingShares = getVestedSharesTotal(vestingData);
  const marketPrice = baseContext.marketPrice;
  const strikePrice = baseContext.strikePrice;

  return vestingShares * (strikePrice - marketPrice);
}

function getRsuValue(baseContext: AssetBaseContext) {
  const vestingData = baseContext.vestingData;
  const vestingShares = getVestedSharesTotal(vestingData);
  const marketPrice = baseContext.marketPrice;

  return vestingShares * marketPrice;
}

function getOptionRsuValue(baseContext: AssetBaseContext) {
  return baseContext.subType === AssetSubTypesKeysToValues.OPTION &&
    baseContext.optionType === 'Buy'
    ? getBuyOptionValue(baseContext as AssetBaseContext)
    : baseContext.subType === AssetSubTypesKeysToValues.OPTION &&
        baseContext.optionType === 'Sell'
      ? getSellOptionValue(baseContext as AssetBaseContext)
      : baseContext.subType === AssetSubTypesKeysToValues.RSU
        ? getRsuValue(baseContext as AssetBaseContext)
        : 0;
}

export function getTotalValue(baseContext: BaseContext): number {
  // TODO return unset value not 0
  switch (baseContext.type) {
    case AssetConst.PRIVATE_EQUITY_FUNDS:
      return baseContext.subType === SupportedPESubtypesList.DIRECT_INVESTING
        ? getOperatingCompanyValue(baseContext as AssetBaseContext)
        : getInvestmentValue(baseContext as AssetBaseContext);
    case AssetConst.VENTURE_CAPITAL:
      return getInvestmentValue(baseContext as AssetBaseContext);
    case AssetConst.INSURANCE:
      return getInsuranceValue(baseContext as AssetBaseContext);
    case AssetConst.REAL_ESTATE:
      if (baseContext.subType === SupportedRESubtypesList.REAL_ESTATE_FUND)
        return getInvestmentValue(baseContext as AssetBaseContext);
      if (baseContext.subType === SupportedRESubtypesList.REIT)
        return getAngelValue(baseContext as AssetBaseContext);
      if (baseContext.subType === SupportedRESubtypesList.DIRECT_OWNERSHIP)
        return getMiscValue(baseContext as AssetBaseContext);
      return getMiscValue(baseContext as AssetBaseContext);
    case AssetConst.ANGEL_INVENSTING:
    case AssetConst.HEDGE_FUNDS_ALTERNATIVES:
      return getAngelValue(baseContext as AssetBaseContext);
    case AssetConst.OPERATING_COMPANY:
      return getOperatingCompanyValue(baseContext as AssetBaseContext);
    case AssetConst.PUBLIC_EQUITY:
    case AssetConst.CASH_AND_CASH_EQUIVALENTS:
    case AssetConst.MISC:
      return getMiscValue(baseContext as AssetBaseContext);
    case AssetConst.LOAN_RECEIVABLE:
      return getLoanValue(baseContext as AssetBaseContext);
    case AssetConst.OPTION_RSU:
      return getOptionRsuValue(baseContext as AssetBaseContext);
    default:
      return baseContext.value ? Number(baseContext.value) : 0;
  }
}

export function setNewValue(newValue, valueList, fieldName, setValue) {
  if (newValue !== Number(valueList[fieldName])) {
    setValue(fieldName, newValue.toString(), {
      shouldDirty: true,
      shouldTouch: true,
    });
  }
}

export function getValue(baseContext: BaseContext): number {
  const totalValue = getTotalValue(baseContext);
  return baseContext.percentageOwned && totalValue
    ? calcPercent(totalValue, Number(baseContext.percentageOwned))
    : totalValue;
}

export function getCost(
  baseContext: BaseContext,
  newBaseContext: BaseContext = {},
  newValue?: number,
): number {
  let getItemCost: (
    baseContext: AssetBaseContext,
    newBaseContext: AssetBaseContext,
    newValue?: number,
  ) => number;

  switch (baseContext.type) {
    case AssetConst.PRIVATE_EQUITY_FUNDS:
      getItemCost =
        baseContext.subType === SupportedPESubtypesList.DIRECT_INVESTING
          ? getOperatingCompanyCost
          : getInvestmentCost;
      break;
    case AssetConst.VENTURE_CAPITAL:
      getItemCost = getInvestmentCost;
      break;
    case AssetConst.REAL_ESTATE:
      getItemCost = getMiscCost;
      if (baseContext.subType === SupportedRESubtypesList.REAL_ESTATE_FUND)
        getItemCost = getInvestmentCost;
      if (baseContext.subType === SupportedRESubtypesList.REIT)
        getItemCost = getAngelCost;
      if (baseContext.subType === SupportedRESubtypesList.DIRECT_OWNERSHIP)
        getItemCost = getMiscCost;
      break;
    case AssetConst.HEDGE_FUNDS_ALTERNATIVES:
    case AssetConst.ANGEL_INVENSTING:
      getItemCost = getAngelCost;
      break;
    case AssetConst.OPERATING_COMPANY:
      getItemCost = getOperatingCompanyCost;
      break;
    case AssetConst.INSURANCE:
      getItemCost = getInsuranceCost;
      break;
    case AssetConst.PUBLIC_EQUITY:
    case AssetConst.CASH_AND_CASH_EQUIVALENTS:
    case AssetConst.MISC:
      getItemCost = getMiscCost;
      break;
    case AssetConst.LOAN_RECEIVABLE:
      getItemCost = getLoanCost;
      break;

    default:
      getItemCost = () => (baseContext.value ? baseContext.value : 0);
      break;
  }
  return getItemCost(
    baseContext as AssetBaseContext,
    newBaseContext as AssetBaseContext,
    newValue,
  );
}

export function removeRenderValues(baseContext: BaseContext) {
  const cleanBaseContext = Object.keys(baseContext).reduce((result, key) => {
    if (key.slice(0, 6) !== 'render') {
      result[key] = baseContext[key];
    }
    return result;
  }, {} as BaseContext);
  return cleanBaseContext;
}

export function getMinValuationDate(itemData: Item) {
  const prevValuationDate =
    itemData.baseContext.valuationDate || itemData.baseContext.purchaseDate;
  const minDate = prevValuationDate
    ? moment(prevValuationDate, 'MM/DD/YYYY')
    : moment(itemData.createdOn);
  return minDate;
}

export const formatCurrency = (
  valuationCurrency: ValidCurrencies,
  value?: string | number,
  fractionDigits = 0,
) =>
  !Number.isNaN(value) &&
  formatCurrencyToLocale(valuationCurrency, Number(value), fractionDigits);

export const formatDate = (date?: string): string =>
  date && moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : 'N/A';

/**
 * The `formatNumber` function formats a number or string as a formatted number with a specified style
 * and number of decimal places.
 * @param {string | number} [value] - The `value` parameter is the number or string that you want to
 * format. It can be either a number or a string representation of a number. If it is not provided, the
 * default value is `undefined`.
 * @param {string} [style=decimal] - The `style` parameter determines the formatting style of the
 * number. It can have the following values:
 * @param {number} [fractionDigits] - The `fractionDigits` parameter is an optional parameter that
 * specifies the number of digits to display after the decimal point. If this parameter is not
 * provided, the default behavior is determined by the `style` parameter.
 *
 * ### Usage
 * ```tsx
 * import { formatNumber } from 'assetValues'
 *
 * formatNumber(value, 'decimal', 2)
 *
 * ```
 *
 */
export const formatNumber = (
  value?: string | number,
  style: 'decimal' | 'currency' | 'percent' = 'decimal',
  fractionDigits?: number,
) =>
  new Intl.NumberFormat('en-CA', {
    style,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(Number(value) || 0);

export const formatToTwoDecimals = (value?: string | number) =>
  formatNumber(value, 'decimal', 2);

/**
 * The function `formatCurrencyLabel` formats a value as a currency and appends the currency symbol.
 * @param {number | string} value - The `value` parameter can be either a number or a string. It
 * represents the numerical value that you want to format as a currency.
 * @param {ValidCurrencies} currency - The `currency` parameter is a string representing a valid
 * currency code.
 * @returns a string that consists of the formatted value followed by a space and the currency symbol.
 * If either the value or the currency is falsy (null, undefined, empty string, etc.), it will return
 * the value as is.
 */
export function formatCurrencyLabel(
  value: number | string,
  currency: ValidCurrencies,
) {
  const formattedValue = formatCurrencyToLocale(currency, Number(value));
  return value && currency ? `${formattedValue} ${currency}` : value;
}

export function getTotalValueOfClass(
  assetsData: Item[],
  assetClass: string,
  groups: GroupData[],
): number {
  if (!assetsData || !assetClass) {
    return 0;
  }
  let total = 0;
  for (const asset of assetsData) {
    let value = asset.baseContext.renderValue;
    // convert percentage if consolidated
    if (groups.length > 0) {
      const groupStakePercentage = groups.find(
        group => group._id === asset.groupId,
      )?.groupStakePercentage;
      value = calcPercent(value, Number(groupStakePercentage));
    }
    total += value;
  }

  return total;
}

// !deprecated this is legacy, should be removed
export function getValuationChoices(values) {
  const valuationChoices: ValuationChoices = {} as ValuationChoices;
  if (values.hasValuation !== undefined && !values.hasValuation) {
    valuationChoices.industry = values.industry;
    valuationChoices.currency = values.currency;
    valuationChoices.ebitda = parseInt(values.ebitda);
    valuationChoices.resultingMultiplier = parseInt(values.multiplier);
    const array = values.yearEndDate.split('/').map(Number);
    valuationChoices.yearEndDate = {
      month: array[0],
      day: array[1],
      year: new Date().getUTCFullYear(),
    };
  }
  return valuationChoices;
}

export function findAssociatedItemArray(
  array: Item[],
  itemData: Item,
  linkedName: string,
) {
  const associatedItemArray: Item[] = [];
  if (!Array.isArray(array)) {
    return associatedItemArray;
  }
  array.forEach(item => {
    if (itemData[linkedName]?.includes(item._id)) {
      associatedItemArray.push(item);
    }
  });

  return associatedItemArray;
}

export function getGroupName(groups: GroupData[], groupId: string) {
  const group = groups?.find(group => group._id === groupId);
  return group?.groupName || '';
}

export const getTotalDashboardItemsValue = (
  itemsByTypes: Record<string, Item[]>,
  groups: GroupData[],
): number => {
  let total = 0;
  if (itemsByTypes) {
    Object.keys(itemsByTypes).forEach(className => {
      total =
        total +
        getTotalValueOfClass(itemsByTypes[className], className, groups);
    });
  }
  return total;
};

export function getSortedAssetLiabilityList(
  items: AssetByType | LiabilityByType | SubsidiaryByType,
  groups: GroupData[],
) {
  let result: string[] = [];
  if (items) {
    result = Object.keys(items).sort((class1, class2) => {
      const totalValue1 = getTotalValueOfClass(items[class1], class1, groups);
      const totalValue2 = getTotalValueOfClass(items[class2], class2, groups);

      return totalValue1 < totalValue2 ? 1 : -1;
    });
  }
  return result;
}

// START REFACTORING (remove when done):

export const getItemAverages = ({
  data,
  groups,
}: {
  data: Record<string, Item[]>;
  groups: GroupData[];
}): HoldingReadingInterface[] => {
  if (!data || !Object.keys(data).length) return [];
  const totalValue = getTotalDashboardItemsValue(data, groups);
  return Object.keys(data)
    .map(eachAsset => {
      const addedSum: number = getTotalValueOfClass(
        data[eachAsset],
        eachAsset,
        groups,
      );
      const value: number = parseFloat(
        ((addedSum / totalValue) * 100).toFixed(2),
      );

      return { className: eachAsset, value };
    })
    .sort((readingA, readingB) => readingB.value - readingA.value);
};

/**
 * The function `getPositionValueByType` takes a list of positions and accumulates their values based
 * on their class type.
 * @param {Position[]} positionList - An array of Position objects. Each Position object has properties
 * such as "class", "marketValue", "quantity", and "bookValue".
 * @param accumulatedItems - The `accumulatedItems` parameter is an object that represents the
 * accumulated items. It has keys that correspond to different item types, and the values are arrays of
 * items of that type. Each item in the array has a `baseContext` property, which contains the `value`
 * and `cost`
 * @returns The function `getPositionValueByType` returns an object that contains accumulated items
 * grouped by their type. The object has keys representing the item types and values representing an
 * array of items of that type. Each item in the array has a `baseContext` property with the `value`
 * and `cost` calculated based on the `marketValue`, `quantity`, and `bookValue` properties of the
 * corresponding
 */
const getPositionValueByType = (
  positionList: Item,
  accumulatedItems: { [k: string]: Item[] },
) => {
  const typeClassEquivalency = {
    equity: 'STOCKS',
    fixed_income: 'FIXED_INCOME',
    cash_equivalent: 'CASH_AND_CASH_EQUIVALENTS',
    foreign_currency: 'FOREIGN_CURRENCY',
    other: 'COMODITY',
  };

  return positionList.baseContext.positions?.reduce(
    (acc, curr) => {
      const itemType = typeClassEquivalency[curr.class];
      if (!itemType) return acc;

      acc[itemType] = [
        ...(Array.isArray(acc[itemType]) ? acc[itemType] : []),
        ...[
          {
            ...positionList,
            itemType: SupportedItemTypes.GROUP_ASSET,
            baseContext: {
              ...positionList.baseContext,
              ...curr,
              name: curr.securityName,
              renderValue: (curr.marketValue || 0) * curr.quantity,
              renderCost: (curr.renderBookValue || 0) * curr.quantity,
            },
          } as unknown as Item,
        ],
      ];
      return acc;
    },
    { ...accumulatedItems },
  );
};

/**
 * Groups items by their `baseContext` type.
 *
 * If `isCalc` is true and the item's type is `AssetConst.PUBLIC_EQUITY`,
 * it processes the item's positions using `getPositionValueByType`.
 *
 * @param items - Array of items to group.
 * @param isCalc - Optional flag to trigger additional calculations for PUBLIC_EQUITY items.
 * @returns A record with item types as keys and arrays of items as values.
 */
export const groupItemsByType = (
  items: Item[],
  isCalc?: boolean,
  isAllocationFilter?: boolean,
): Record<string, Item[]> =>
  items.reduce((acc: { [k: string]: Item[] }, curr) => {
    if (
      isAllocationFilter &&
      curr.baseContext.positions &&
      curr.baseContext.positions.length
    ) {
      return {
        ...acc,
        ...getPositionValueByType(curr, acc),
      };
    }

    const currentType = [
      ...(Array.isArray(acc[curr.baseContext.type as string])
        ? acc[curr.baseContext.type as string]
        : []),
      ...[curr],
    ];

    return {
      ...acc,
      [curr.baseContext.type as string]: currentType,
    };
  }, {});

/**
 * The function calculates various values related to a financial position, such as gain amount, gain
 * currency amount, gain percent, and total quantity.
 * @param {PositionWithUpdate} position - The `position` parameter is an object that represents a
 * position with update. It contains the following properties:
 * @param {Position} [prevPosition] - The `prevPosition` parameter is an optional parameter of type
 * `Position`. It represents the previous position before the current position.
 * @returns an object with the following properties: gainAmount, gainCurrencyAmount, gainPercent, and
 * totalQuantity.
 */
export const getCalculatedPositionValues = (
  position: PositionWithUpdate,
  prevPosition?: Position,
): any => {
  let totalCost;
  let totalQuantity;

  if (prevPosition) {
    if (position?.action === 'MARKET_VALUE') {
      totalQuantity = position.quantity;
      totalCost = position.bookValue * position.quantity;
    } else if (position?.action === 'SELL') {
      totalQuantity = prevPosition.quantity - position.quantity;

      totalCost =
        (prevPosition.bookValue * prevPosition.quantity +
          position.bookValue * position.quantity) /
        totalQuantity;
    } else {
      totalQuantity = prevPosition.quantity + position.quantity;

      totalCost =
        (prevPosition.bookValue * prevPosition.quantity +
          position.bookValue * position.quantity) /
        totalQuantity;
    }
  } else {
    totalQuantity = position.quantity;
    totalCost = position.bookValue * position.quantity;
  }

  const totalValue = position.marketValue * totalQuantity;
  const gainAmount = totalValue - totalCost;
  const gainCurrencyAmount = totalValue - totalCost;
  const gainPercent = (gainAmount / totalCost) * 0.1;

  return { gainAmount, gainCurrencyAmount, gainPercent, totalQuantity };
};

/**
 * The function calculates the total cost of positions by summing up their book values.
 * @param {Position[]} positions - An array of Position objects. Each Position object has a property
 * called "bookValue" which represents the cost of that position.
 * @returns the total cost of all positions in the given array.
 */
export const getPositionsCost = (positions: Position[]): number => {
  return positions?.reduce(
    (total, position) => total + position.bookValue * position.quantity,
    0,
  );
};

/**
 * The function `getPositionsValue` calculates the total market value of an array of positions.
 * @param {Position[]} positions - An array of Position objects. Each Position object has a property
 * called "marketValue" which represents the market value of that position.
 * @returns the total market value of all positions in the given array.
 */
export const getPositionsValue = (positions: Position[]): number => {
  return positions?.reduce(
    (total, position) => total + position.marketValue * position.quantity,
    0,
  );
};

/**
 * The function calculates the total value of positions that are classified as cash equivalents.
 * @param {Position[]} positions - An array of Position objects. Each Position object has the following
 * properties:
 * @returns the total value of positions that are classified as 'cash_equivalent'.
 */
export const getPositionsValueWithCash = (positions: Position[]): number => {
  return positions?.reduce(
    (total, position) =>
      position.class === 'cash_equivalent'
        ? total + position.marketValue * position.quantity
        : total,
    0,
  );
};

/**
 * The function calculates the total number of vested shares based on the vesting data provided.
 * @param {any} vestingData - The `vestingData` parameter is an array of objects that contains
 * information about vested shares. Each object in the array represents a vesting event and has the
 * following properties:
 * @returns the total number of vested shares.
 */
export function getVestedSharesTotal(vestingData: any) {
  let vestedShares = 0;
  vestingData?.forEach((element: any) => {
    const today = new Date();
    if (!element?.numberOfVesting && new Date(element?.vestingDate) < today) {
      vestedShares += parseInt(element?.numberOfVesting);
    }
  });

  return vestedShares;
}

/**
 * The function calculates the difference between consecutive items in an array and returns the values,
 * optionally negating them if isCashFlow is true.
 * @param {number[]} items - An array of numbers representing the values of items.
 * @param [isCashFlow=false] - The `isCashFlow` parameter is a boolean flag that determines whether the
 * calculated differences should be treated as cash flow values. If `isCashFlow` is `true`, the
 * function will multiply each difference by -1 if it is not zero. If `isCashFlow` is `false`
 * @returns The function `calculateDifferencePerItems` returns an array of numbers. If the `isCashFlow`
 * parameter is `true`, the function returns an array where each non-zero value is multiplied by -1.
 * Otherwise, it returns the original array of differences between consecutive items.
 */
const calculateDifferencePerItems = (items: number[], isCashFlow = false) => {
  const values: number[] = [items[0]];

  for (let i = 1; i < items.length; i++) {
    const cash = items[i] - items[i - 1];
    values.push(cash);
  }

  if (isCashFlow) {
    return values.map(value => value * -1);
  }
  return values;
};

const getAngelOutflow = (history: ValueHistory[]): number[] => {
  const costList = history.map(
    ({ sharesPurchased, recentSharePrice, ...shareContext }) => {
      if (shareContext.transactionType === TransactionGroupThreeTypeList.SELL) {
        return 0;
      }
      return Number(sharesPurchased) * Number(recentSharePrice) * -1 || 0;
    },
  );
  return costList;
};

const getCashOutflows = (history: ValueHistory[]): number[] => {
  const cost = history.map((baseContext): number => {
    return getCost(baseContext);
  });
  if (!history[0]) return calculateDifferencePerItems(cost, true);
  const { type, subType } = history[0];

  switch (type) {
    case AssetConst.ANGEL_INVENSTING:
    case AssetConst.HEDGE_FUNDS_ALTERNATIVES:
      return getAngelOutflow(history);
    case AssetConst.REAL_ESTATE:
      if (subType === AssetConst.PRIVATE_REIT) {
        return getAngelOutflow(history);
      } else {
        return calculateDifferencePerItems(cost, true);
      }
    case AssetConst.PRIVATE_EQUITY_FUNDS:
      return subType === SupportedPESubtypesList.DIRECT_INVESTING
        ? getAngelOutflow(history) // TODO should have op company outflow
        : calculateDifferencePerItems(cost, true);
    case AssetConst.VENTURE_CAPITAL:
    case AssetConst.MISC:
    default:
      return calculateDifferencePerItems(cost, true);
  }
};

/**
 * The function takes two arrays of numbers and returns a new array that alternates the elements from
 * the two input arrays.
 * @param {number[]} array1 - An array of numbers
 * @param {number[]} array2 - The `array2` parameter is an array of numbers.
 * @returns The function `insertAlternativeArrays` returns an array of numbers.
 */
const insertAlternativeArrays = (array1: number[], array2: number[]) => {
  const results: number[] = [];

  for (let i = 0; i < array1.length; i++) {
    results.push(array1[i]);
    results.push(array2[i]);
  }

  return results;
};

export const getCashValuation = (history: ValueHistory[]) => {
  if (!history[0]) return [];
  const { type, subType } = history[0];
  const cashValuation = history.map(({ totalValue }, index) => {
    if (index === 0) return Number(totalValue);
    return (
      Number(totalValue) +
      Number(history[index - 1]?.distribution ?? 0) -
      Number(history[index - 1]?.totalValue ?? 0)
    );
  });
  const estateValuation = history.map(({ totalValue, distribution }, index) => {
    let realDistribution = 0;
    let returnCapital = Number(distribution ?? 0);
    if (Number(totalValue) === returnCapital) {
      realDistribution = returnCapital;
      returnCapital = 0;
    }
    return (
      realDistribution +
      returnCapital +
      Number(totalValue) -
      Number(history[index - 1]?.totalValue ?? 0)
    );
  });
  const sharesValuation = history.map(
    (record, index) =>
      (record.transactionType === TransactionGroupThreeTypeList.SELL
        ? 0
        : Number(record.distribution || 0)) +
      getUnrealizedGain(record) +
      getRealizedGain(record, history[index - 1]) +
      Number(record.cost || 0),
  );
  switch (type) {
    case AssetConst.PRIVATE_EQUITY_FUNDS:
    case AssetConst.VENTURE_CAPITAL:
      /**
       *
       *   iv = ivpd - d
       *   ivpd = iv + d
       *   ivpd = iv + rc - id
       *
       */
      return calculateDifferencePerItems(
        history.map(
          ({ totalValue, distribution }) =>
            totalValue + Number(distribution || 0),
        ),
      );
    case AssetConst.ANGEL_INVENSTING:
    case AssetConst.HEDGE_FUNDS_ALTERNATIVES:
      return sharesValuation;
    case AssetConst.REAL_ESTATE:
      return subType === AssetConst.OWNERSHIP
        ? estateValuation
        : sharesValuation;
    case AssetConst.MISC:
      return cashValuation;
    default:
      return [];
  }
};

/**
 * The `calcIrr` function calculates the internal rate of return (IRR) based on a given history of
 * capital committed and investment valuation.
 * @param {ValueHistory[]} history - An array of objects representing the historical values of an
 * investment. Each object should have the properties "totalCommitment" and "totalValue", which
 * represent the total amount of capital committed and the total value of the investment at a specific
 * point in time.
 * @param [initialGuess=0.1] - The initialGuess parameter is the initial estimate for the internal rate
 * of return (IRR) calculation. It is used as the starting point for the iterative process to find the
 * IRR. If no initial guess is provided, the default value is 0.1.
 * @returns the internal rate of return (IRR) as a string with a percentage sign.
 */
export const calcIrr = (history: ValueHistory[], initialGuess = 0.5) => {
  const cashOutflow = getCashOutflows(history);
  const changeInInvestmentValuation = getCashValuation(history);
  const cashflow = insertAlternativeArrays(
    cashOutflow,
    changeInInvestmentValuation,
  );
  const NPV = (cashflow: number[], discountRate: number) => {
    return cashflow.reduce(
      (acc, val, i) => acc + val / Math.pow(1 + discountRate, i),
      0,
    );
  };

  const maxTries = 10000;
  const delta = 0.001;
  let guess = initialGuess;
  const calculatedNpv = NPV(cashflow, guess);

  const multiplier = calculatedNpv > 0 ? 1 : -1;

  for (let i = 0; i < maxTries; i++) {
    const guessedNPV = NPV(cashflow, guess);
    if (multiplier * guessedNPV > delta) {
      guess += multiplier * delta;
      i += 1;
    }
  }

  const irrRound = Math.round(guess * 10000) / 100;
  const irr = parseFloat(irrRound.toString()).toFixed(1) + '%';

  return irr;
};

export const getUnrealizedGain = (baseContext: BaseContext) => {
  let unrealizedGain = 0;
  switch (baseContext.type) {
    case AssetConst.PRIVATE_EQUITY_FUNDS:
    case AssetConst.VENTURE_CAPITAL:
      unrealizedGain = getUnRealizedVcPe(baseContext);
      break;
    case AssetConst.ANGEL_INVENSTING:
    case AssetConst.HEDGE_FUNDS_ALTERNATIVES:
      unrealizedGain = getUnRealizedShares(baseContext);
      break;
    case AssetConst.REAL_ESTATE:
      unrealizedGain =
        baseContext.subType === AssetConst.OWNERSHIP
          ? getUnRealizedRealState(baseContext)
          : getUnRealizedShares(baseContext);
      break;
    case AssetConst.MISC:
      unrealizedGain = getUnRealizedRealState(baseContext);
      break;
    default:
      return unrealizedGain;
  }
  return Number.isNaN(unrealizedGain) ? 0 : unrealizedGain;
};

export const getRealizedGain = (
  baseContext: BaseContext,
  prevBaseContext?: BaseContext,
) => {
  let realizedGain = 0;
  switch (baseContext.type) {
    case AssetConst.PRIVATE_EQUITY_FUNDS:
    case AssetConst.VENTURE_CAPITAL:
      realizedGain = getRealizedVcPe(baseContext);
      break;
    case AssetConst.ANGEL_INVENSTING:
    case AssetConst.HEDGE_FUNDS_ALTERNATIVES:
      realizedGain = getRealizedShares(baseContext, prevBaseContext);
      break;
    case AssetConst.REAL_ESTATE:
      realizedGain =
        baseContext.subType === AssetConst.OWNERSHIP
          ? getRealizedReMisc(baseContext)
          : getRealizedShares(baseContext, prevBaseContext);
      break;
    case AssetConst.MISC:
      realizedGain = getRealizedReMisc(baseContext);
      break;
    default:
      return realizedGain;
  }
  return Number.isNaN(realizedGain) ? 0 : realizedGain;
};

const getUnRealizedVcPe = (baseContext: BaseContext) => {
  let returnCapital = 0;
  if (baseContext.transactionType === TransactionGroupOneTypeList.CAPITAL) {
    returnCapital = Number(baseContext.distribution ?? 0);
  }
  return (
    Number(baseContext.totalValue) -
    (Number(baseContext.totalCommitment) - returnCapital)
  );
};

const getUnRealizedRealState = (baseContext: BaseContext) => {
  if (Number(baseContext.totalValue) <= Number(baseContext.distribution))
    return 0;
  const price =
    Number(baseContext.totalValue) -
    Number(baseContext.purchasePrice) -
    Number(baseContext.distribution) *
      ((Number(baseContext.totalValue) - Number(baseContext.purchasePrice)) /
        Number(baseContext.totalValue));
  return price;
};

const getUnRealizedShares = (baseContext: BaseContext) => {
  return Number(baseContext.value - baseContext.cost);
};

const getRealizedVcPe = (baseContext: BaseContext) => {
  let realizedGain = 0;
  if (
    [
      TransactionGroupOneTypeList.CAPITAL,
      TransactionGroupOneTypeList.DIVIDEND,
      TransactionGroupOneTypeList.FROM_SALE,
    ].includes(baseContext.transactionType)
  ) {
    realizedGain =
      Number(baseContext.distribution ?? 0) -
      Number(baseContext.totalCommitment);
  }
  return realizedGain;
};

const getRealizedReMisc = (baseContext: BaseContext) => {
  if (baseContext.distribution < 0) return 0;
  const price =
    Number(baseContext.distribution) -
    Number(baseContext.purchasePrice) *
      (Number(baseContext.distribution) / Number(baseContext.totalValue));

  return price;
};

const getRealizedShares = (
  baseContext: BaseContext,
  prevBaseContext?: BaseContext,
) => {
  if (baseContext.transactionType !== TransactionGroupThreeTypeList.SELL)
    return 0;
  return Math.abs(
    Number(baseContext.distribution) +
      (Number(prevBaseContext?.cost) / Number(prevBaseContext?.sharesNumber) ||
        0) *
        Number(baseContext.sharesPurchased),
  );
};

/**
 * The function `sortGroupItems` takes an array of groups, filters out deleted items, and returns an
 * array of group items grouped by type.
 * @returns The function `sortGroupItems` returns an array containing the result of calling the
 * `groupItemsByType` function on the filtered `groupItems` array.
 */
export const sortGroupItems = groups => {
  const groupItems = Array.isArray(groups)
    ? groups
        .flatMap(g => g.items)
        .filter(({ status }) => status === SupportedStatus.ACTIVE)
    : [];
  return [
    groupItemsByType(groupItems.filter(i => i.itemType === 'GROUP_ASSET')),
    groupItemsByType(groupItems.filter(i => i.itemType === 'GROUP_LIABILITY')),
  ];
};

export const sortAllGroupItems = groups =>
  groupItemsByType(
    groups
      ?.flatMap(g => g.items)
      .filter(
        i =>
          i.itemType !== SupportedItemTypes.UNKNOWN &&
          i.status === SupportedStatus.ACTIVE,
      ) || [],
  );

/* ==================================== */

export const getItemTitleByClass = (assetClass: string): string =>
  LiabilityTypesKeysToValues[assetClass] ||
  (AssetClassesKeysToValues[assetClass] &&
    AssetClassesKeysToValues[assetClass].label) ||
  assetClass;

//TODO: Make all this functions available for the following modules: Consolidation, Vault

/**
 * The function `getItemTypeList` processes an array of assets or liabilities to categorize them by
 * type and create a list of unique item types.
 * @param {Asset[] | Liability[]} [assetsData] - The `assetsData` parameter in the `getItemTypeList`
 * function is an optional array of objects that can be either of type `Asset[]` or `Liability[]`.
 * These objects represent financial assets or liabilities and contain information such as the type of
 * the item (e.g., "cash",
 * @returns The function `getItemTypeList` returns an object with two properties: `itemTypeList` and
 * `itemListByType`. `itemTypeList` is a Set containing unique item types, and `itemListByType` is an
 * object where each key is an item type and the value is an array of items belonging to that type.
 */
export const getItemTypeList = (
  assetsData?: Asset[] | Liability[] | Subsidiary[],
): ItemTypeList => {
  const itemTypeList = new Set<string>();
  const itemListByType = {};
  assetsData?.forEach(item => {
    const { type } = item.baseContext;
    const itemType = type;
    if (itemType === 'undefined' || !itemType || !item) return;
    if (!itemListByType[itemType]) itemListByType[itemType] = [];
    itemListByType[itemType].push(item);
    itemTypeList.add(itemType);
  });
  return { itemTypeList, itemListByType };
};

/**
 * The function `getSortedItems` sorts an array of `Asset` or `Liability` objects based on their
 * `renderValue` property in descending order.
 * @param {Asset[] | Liability[] | Subsidiary[]} items - The `items` parameter in the `getSortedItems` function can be
 * either an array of `Asset` objects or an array of `Liability` objects.
 */
export const getSortedItems = (
  items: Asset[] | Liability[] | Subsidiary[],
): Asset[] | Liability[] | Subsidiary[] =>
  items.sort(
    (data1, data2) =>
      data2.baseContext.renderValue - data1.baseContext.renderValue,
  );
