import shadows from './shadows';

export const variants = {
  primaryLink: {
    base: 'RobotoRegular',
    color: 'darkerGold',
    fontSize: 14,
    textDecoration: 'none',
  },
  dividerStyles: {
    borderColor: 'other.divider',
    my: '1.5rem',
  },
  boxContainer: {
    background: '#F8FBFC',
    minHeight: '100vh',
    paddingTop: 4,
  },
  tableColumn: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  navBar: {
    color: 'black',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 1px 0px #E5E8E7',
    borderBottom: '1px solid #EBEAE8',
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    height: 75,
  },
  card: {
    background: '#FFFFFF',
    borderRadius: 4,
    padding: 30,
  },
  defaultBorder: {
    border: '1px solid #E7E7E8',
    borderRadius: 4,
  },
  basicSolidBorder: '1px solid',
  componentBorder: '1px solid #EBEAE8',
  modalBorder: '1px solid #9C968E4D',
  modalBoxShadow: '0px 6px 20px 0px #0000001A',
  tooltipBoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  accordionSummary: {
    '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {},
    '.MuiAccordionSummary-expandIconWrapper': {
      color: '#9FA3A6',
    },
  },
  accordion: {
    backgroundColor: '#F8F8F9',
    borderRight: '1px solid #EBEAE8',
    borderLeft: '1px solid #EBEAE8',
    borderBottom: '1px solid #EBEAE8',
  },
  boxStyles: {
    border: '1px solid #E5E8E7',
    borderRadius: '4px',
    background: 'white',
    padding: 4,
    width: '32rem',
    marginX: 'auto',
  },
  boxUpperTitleDefault: {
    paddingBottom: '24px',
    marginBottom: '48px',
    borderBottom: '1px solid #EBEAE8',
  },
  gridInputLabel: { mb: 0, mt: -1 },
  inputLabel: { mt: -1 },
  disclaimerStyles: {
    fontSize: 8,
  },
  disclaimerBoxStyle: {
    display: 'flex',
  },
  boxVerificationStyles: {
    backgroundColor: 'white',
    position: 'relative',
    padding: 5,
    marginTop: 5,
    marginX: 'auto',
    border: '1px solid #E5E8E7',
    boxShadow:
      '0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);',
    width: '46rem',
  },
  boxMarginStyles: {
    marginX: -2,
    marginBottom: 3,
  },
  primaryLabel: {
    fontSize: '12px',
    color: 'black',
    marginBottom: 2,
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  secondaryLabel: {
    color: 'darkGray',
    marginBottom: 4,
    whiteSpace: 'pre-wrap',
  },
  iconStyles: {
    width: 60,
    height: 60,
    padding: 2,
    backgroundColor: 'lightGray',
    borderRadius: '50%',
  },
  pxBox: { paddingX: 2 },
  mtmbBox: { marginTop: '48px', marginBottom: '30px' },

  mbTextPrimary: { marginBottom: 2 },
  mbHeadingPrimary: { marginBottom: '1em' },
  widthField: { width: 1 },
  displayContainer: { display: 'flex', justifyContent: 'center' },
  mrBox: { marginRight: 1 },
  mlBox: { marginLeft: 1 },

  boxFlexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomButtonsPositionBox: {
    position: 'absolute',
    bottom: '-30px',
    left: '0px',
    right: '0',
    width: '100%',
    padding: '20px 0',
    paddingRight: '30px',
    background: '#fff',
  },
  bottomButtonsBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paddingTop20: {
    padding: '3em 0',
  },
  bottomRightButtonsBox: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '48px',
  },
  bottomRightButtonsFlexBox: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    padding: '20px 0',
    paddingRight: '60px',
  },
  deleteActionButton: {
    width: '130px',
    justifyContent: 'center',
    color: '#C8291D',
    boxShadow: 'none',
    backgroundColor: '#fff',
    paddingLeft: '0',
    paddingRight: '0',
    ':hover': {
      background: 'none',
    },
  },
  removeButton: {
    width: '92px',
    justifyContent: 'center',
    color: '#C8291D',
    boxShadow: 'none',
    backgroundColor: '#fff',
    ':hover': {
      background: 'none',
    },
  },
  cancelButton: {
    width: '92px',
    justifyContent: 'center',
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: '#40464D',
    ':hover': {
      background: 'none',
    },
  },
  boxBottomButtonsDefault: {
    paddingTop: '48px',
    marginTop: '48px',
    borderTop: '1px solid #EBEAE8',
  },
  //please use in pair with bottomRightButtonSecondary:
  bottomRightButtonPrimary: {
    color: 'rgba(16, 24, 32, 0.6)',
    marginRight: '1rem',
    height: '42px',
  },
  bottomRightButtonPrimaryOutlined: {
    marginRight: '1rem',
    height: '42px',
    paddingX: '26px',
  },
  //please use in pair with bottomRightButtonPrimary
  bottomRightButtonSecondary: {
    color: 'white',
    backgroundColor: 'primary.main',
    boxShadow: shadows[12],
    height: '42px',
    paddingX: '26px',
  },
  bottomRightButtonCancel: {
    color: 'error.main',
    backgroundColor: 'error.contrast',
    boxShadow: shadows[12],
    height: '42px',
    paddingX: '26px',
  },
  buttonLargePrimary: {
    paddingX: '8px',
    width: '250px',
    backgroundColor: 'primary.main',
    height: '42px',
    color: 'primary.contrast',
    boxShadow: shadows[12],
    borderRadius: '4px',
  },
  outlinedButtonLargePrimary: {
    paddingX: '8px',
    width: '250px',
    backgroundColor: 'primary.contrast',
    height: '42px',
    color: 'primary.main',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'primary.main',
  },
  buttonAddAction: {
    paddingX: '15px',
    fontSize: '14px',
    backgroundColor: '#50BD7D',
  },
  userHomeContentStyles: {
    padding: '16px',
    border: '1px solid',
    borderColor: 'secondary.12p',
    boxShadow: shadows[12],
  },
  dashboardTotalAssetStyles: {
    color: 'white',
    padding: '16px',
    border: '1px solid',
    background:
      'linear-gradient(to bottom, rgba(64, 79, 73, 1) 36.98%, rgba(64, 79, 73, 0) 100%)',
    boxShadow: shadows[12],
  },

  containerBoxShadow: {
    boxShadow:
      '0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08)',
  },

  accountPaper: { width: '40rem', padding: '2rem', margin: '0 auto' },

  accountFormPaddingY: {
    paddingY: '2rem',
  },

  formSpacingBetweenFields: {
    display: 'Flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  textFieldMedium: {
    width: '280px',
  },
  inputFieldLarge: {
    width: '37rem',
  },
};
