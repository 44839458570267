import React, { PropsWithChildren } from 'react';
import { Box, Card, Typography, Button } from '@mui/material';
import { ErrorOutline, KeyboardArrowDown /* Add*/ } from '@mui/icons-material';
// import { capitalize } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';

import { AssetRow } from 'app/components/Assets/AssetRow/Loadable';
import { ListItemAccordion } from 'app/components/ListItemAccordion/Loadable';

import {
  Asset,
  Item,
  SupportedItemTypes,
  Liability,
  Subsidiary,
} from 'app/features/AssetLiabilitySlice/types';
import { EditLiabilityStyle } from 'styles/style';
import { LabelText } from 'app/components/LabelText';
import style from './style';

interface Props {
  heading: string;
  buttonText?: string;
  itemList?: Item[];
  itemOwner: Item;
  handleItemRemove: (itemId?: any, name?: string) => void;
  onClick?: () => void;
  disableAddButton?: boolean;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  openModal?: boolean;
  onClickEdit: (
    _id: string,
    itemType: SupportedItemTypes,
  ) => (event: React.MouseEvent<HTMLElement>) => void;
  onClickDelete: (
    item: Asset | Liability | Subsidiary,
  ) => (event: React.MouseEvent<HTMLElement>) => void;
}

export function AssetAssociatedView({
  heading,
  buttonText,
  itemList,
  itemOwner,
  handleItemRemove,
  onClick,
  disableAddButton,
  onClickEdit,
  onClickDelete,
  // setOpenModal,
}: Props & PropsWithChildren<{}>) {
  const { t } = useTranslation();

  const handleButtonClick = event => {
    event.stopPropagation();
    onClick && onClick();
  };

  const renderTitleElement = () => (
    <Box sx={style.boxTitleStyles}>
      <Card elevation={0} sx={style.backgroundInherit}>
        <Typography variant="body1" fontSize={16} fontFamily="RobotoRegular">
          {heading}
        </Typography>
      </Card>
      {!disableAddButton && (
        <Card elevation={0} sx={style.backgroundInherit}>
          <Button
            onClick={handleButtonClick}
            type="button"
            sx={style.associateButtonStyles}
            variant="outlined"
          >
            {buttonText}
          </Button>
        </Card>
      )}
    </Box>
  );

  return (
    <Box sx={style.boxContainerStyles}>
      <ListItemAccordion
        defaultExpanded={true}
        name={itemOwner.baseContext?.type}
        title={renderTitleElement()}
        expandIcon={<KeyboardArrowDown />}
        accordionSummarySx={style.accordionSummaryStyles}
        accordionSx={style.accordionStyles}
        contentListSx={style.contentLiStyles}
        accordionDetailsSx={style.accordionDetailsStyles}
      >
        {itemList?.length ? (
          itemList.map((data, index) => {
            return (
              <Box key={index} sx={EditLiabilityStyle.associateBox}>
                {
                  <AssetRow
                    hideview={true}
                    data={data as Liability}
                    handleLiabilityRemove={
                      itemOwner.integrationHash ? undefined : handleItemRemove
                    }
                    onClickEdit={onClickEdit(data._id, data.itemType)}
                    onClickDelete={onClickDelete(data as Liability)}
                  />
                }
              </Box>
            );
          })
        ) : (
          <LabelText
            title={<ErrorOutline sx={{ verticalAlign: 'middle' }} />}
            text={
              itemOwner.itemType === SupportedItemTypes.GROUP_ASSET
                ? // ? itemOwner.baseContext.type === AssetConst.OPERATING_COMPANY
                  //   ? 'All your data will aggregate here once you add it'
                  'No liabilities have been associated to this asset'
                : 'No assets have been associated to this liability'
            }
            variant="variantIcon"
            // helper={
            //   itemOwner.itemType === SupportedItemTypes.GROUP_ASSET &&
            //   itemOwner.baseContext.type === AssetConst.OPERATING_COMPANY &&
            //   setOpenModal ? (
            //     <Button
            //       onClick={() => setOpenModal(true)}
            //       variant="contained"
            //       sx={style.buttonStyles}
            //     >
            //       <Add />
            //       {capitalize(t('itemView.labels.codat'))}
            //     </Button>
            //   ) : null
            // }
          />
        )}
      </ListItemAccordion>
    </Box>
  );
}
